import React, {memo, useContext, useLayoutEffect, useRef} from 'react';
import Chat from '../../Chat/components/Chat';
import { Drawer } from "antd";
import '../../../assets/chat/dialog.scss';
import DialogueHeader from './DialogueHeader';
import { DialogueCloseContext } from '../../../contexts/layoutContexts';
import { useSelectedChatContext, useUpdateMessengerContext } from '../context/MessengerDataProvider';
import ChatDataProvider from '../../Chat/components/ChatDataProvider';

const dialogueDrawerStyle = {position:'relative'}
const dialogueDrawerClasses = {
  body:'dialogue__body'
}

const Dialogue = memo(({dialogueWindowOpen, goToAppFromMessenger}) => {
  const dialogueContainerRef = useRef(null);
  const { selectedChat, selectedChatSnapId } = useSelectedChatContext();
  const { setSelectedChat, setSelectedChatSnapId } = useUpdateMessengerContext();
  const { closeDialogue } = useContext(DialogueCloseContext);

  useLayoutEffect(() => {
    if(dialogueWindowOpen) {
      dialogueContainerRef.current.style.top = `${window.scrollY}px`;
    }
  },[dialogueWindowOpen])

  const dialogueDrawerCloseHandler = () => {
    setSelectedChat(null);
    setSelectedChatSnapId(null);
    closeDialogue();
  }

  return (
    <div
      className='dialogue-drawer__container'
      ref={dialogueContainerRef}
      style={dialogueDrawerStyle}
    >
      <Drawer
        classNames={dialogueDrawerClasses}
        width={600}
        rootClassName="dialogue"
        placement="left"
        title={selectedChat ? <DialogueHeader
          UID={selectedChat.UID}
          supportId={selectedChat.supportId}
          issueId={selectedChat.issueId}
          totalApps={selectedChat.totalApps}
          goToAppFromMessenger={goToAppFromMessenger}
        /> : null}
        mask={false}
        open={dialogueWindowOpen}
        onClose={dialogueDrawerCloseHandler}
        getContainer={false}
        zIndex={99}
      >
        {selectedChat &&  selectedChatSnapId ? ( // потому что при нажатии по кнопке "все заявки" или логотиge и др.- saveSelectedDialogueId(null). И если чат при этом был открыт - он пропадет
          <ChatDataProvider
            UID={selectedChat?.UID}
            selectedChatSnapId={selectedChatSnapId}
          >
            <Chat
              applicantId={selectedChat.UID}
              source="global-chat"
              dialogue={selectedChat}
              selectedChatSnapId={selectedChatSnapId}
            />
          </ChatDataProvider>
        ) : (
          null
        )}
      </Drawer>
    </div>
  );
});

export default Dialogue;