import React, { createContext, useContext, useMemo, useState } from 'react';
import useChatsData from '../hooks/useChatsData';

const ChatsDataContext = createContext({});
const SelectedChatContext = createContext({});
const ChatSearchContext = createContext({});
const UpdateMessengerContext = createContext({});

const MessengerDataProvider = ({children}) => {
  //states
  const [ chatListPages, setChatListPages ] = useState(1);
  const [ selectedChat, setSelectedChat ] = useState(null);
  const [ selectedChatSnapId, setSelectedChatSnapId ] = useState(null);
  const [ chatsSearchFilter, setChatsSearchFilter ] = useState('');
  // download data
  const [ chatsData, chatsLoading, chatsError ] = useChatsData(chatsSearchFilter, chatListPages);

  const chatsDataContext = useMemo(() => {
    return {
      chatsData,
      chatsLoading,
      chatsError,
    }
  }, [chatsData, chatsError, chatsLoading])

  const updateMessengerContext = useMemo(() => {
    return {
      setChatListPages,
      setSelectedChat,
      setChatsSearchFilter,
      setSelectedChatSnapId,
    }
  }, [])

  const chatSearchContext = useMemo(() => ({chatsSearchFilter}), [chatsSearchFilter])
  const selectedChatContext = useMemo(() => ({selectedChat, selectedChatSnapId}), [selectedChat, selectedChatSnapId])

  return (
    <ChatsDataContext.Provider value={chatsDataContext}>
      <SelectedChatContext.Provider value={selectedChatContext}>
        <ChatSearchContext.Provider value={chatSearchContext}>
          <UpdateMessengerContext.Provider value={updateMessengerContext}>
            {children}
          </UpdateMessengerContext.Provider>
        </ChatSearchContext.Provider>
      </SelectedChatContext.Provider>
    </ChatsDataContext.Provider>
  );
};

export default MessengerDataProvider;

export const useChatsContext = () => useContext(ChatsDataContext);
export const useChatSearchContext = () => useContext(ChatSearchContext);
export const useSelectedChatContext = () => useContext(SelectedChatContext);
export const useUpdateMessengerContext = () => useContext(UpdateMessengerContext);
