import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from "@ant-design/icons";
import ApplyOrCancel from '../../../components/ApplyOrCancel';
import "../../../assets/chat/chat-client-name.scss";

// временное решение добавить редактирование клиентского имени сюда. Такое ТЗ :))
const ChatName = ({clientName, editClientFunc, loading, accountIsDeleted}) => {
  const [ newClientName, setNewClientName ] = useState('')
  const [ clientNameIsEdit, setClientNameIsEdit] = useState(false);

  useEffect(() => {
    setClientNameIsEdit(false);
  }, [clientName])

  const handleInputChange = (e) => {
    setNewClientName(e.target.value)
  }

  const enableEditName = () => {
    setNewClientName(clientName);
    setClientNameIsEdit(true);
  }

  const cancelEditName = () => {
    setClientNameIsEdit(false);
    setNewClientName('');
  }

  const applyChanges = async () => {
    const newData = {name: newClientName}
    await editClientFunc(newData)
    setClientNameIsEdit(false)
    setNewClientName(clientName)
  }

  return (
    <div className="chat__applicant-name">
      {clientNameIsEdit ? (
        <>
          <Input size='small' value={newClientName} onChange={handleInputChange} />
          <div className='applicant-name__tools'>
            <ApplyOrCancel isEdit={clientNameIsEdit} applyChanges={applyChanges} cancelChanges={cancelEditName} loading={loading} type='icons' />
          </div>
        </>
      ) : (
        <>
          <span className='applicant-name__name'>
            {clientName}
          </span>
          {accountIsDeleted ? (
            null
          ) : (
            <div className='applicant-name__tools'>
              <EditOutlined
                className="interactive-icons"
                style={{ fontSize: '16px', color: '#08c', marginLeft:"10px", marginRight:"10px"}}
                onClick={enableEditName}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
};

export default ChatName;