import { Timestamp } from 'firebase/firestore';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { addUnsentMsgByUID, getUnsentMessages, removeUnsentMsgByUID, saveUnsentMessages } from '../../../localforage/localforage-api';

const useUnsentMessagesData = (applicantId) => {
  const [ unsentMessages, setUnsentMessages ] = useState([]);

  const fetchUnsentMessages = useCallback(async () => {
    try {
      const res = await getUnsentMessages(applicantId);
      setUnsentMessages(res.map(unsentMessage => ({...unsentMessage, time: Timestamp.fromMillis(unsentMessage.time)})));
    } catch (error) {
      console.log(error);
    }
  }, [applicantId])

  useEffect(() => {
    if(applicantId) {
      setUnsentMessages([]);
      fetchUnsentMessages();
    }
  }, [applicantId, fetchUnsentMessages])

  /**
   * Проверяет непрочитанные сообщения с клиентом, если есть сообщения, с даты написания которых прошло 24 часа и более - стереть их
   */
  useLayoutEffect(() => {
    const id = setInterval(() => {
      if(unsentMessages.length) {
        const check = async () => {
          const nowTimestamp = Date.now();
          const dayBeforeTimestmap = nowTimestamp - 1000*60*60*24;
          const res = await getUnsentMessages(applicantId);
          const updatedUnsentMsgs = res.filter(unsentMsg => unsentMsg.time > dayBeforeTimestmap);
          if(updatedUnsentMsgs.length < res.length) {
            saveUnsentMessages(applicantId, updatedUnsentMsgs);
            setUnsentMessages(updatedUnsentMsgs);
          }
        }
        check();
      }
    }, 300000)
    return () => clearInterval(id)
  }, [applicantId, unsentMessages.length])

  const addUnsentMessage = (UID, newUnsentMsg) => {
    addUnsentMsgByUID(UID, newUnsentMsg);
    setUnsentMessages(prev => [...prev, newUnsentMsg]);
  }

  const removeUnsentMessage = (UID, idToDelete) => {
    removeUnsentMsgByUID(UID, idToDelete);
    setUnsentMessages(prev => prev.filter(unsentMsg => unsentMsg.id !== idToDelete));
  }

  return [
    unsentMessages,
    addUnsentMessage,
    removeUnsentMessage,
  ]
};

export default useUnsentMessagesData;