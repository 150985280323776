import { chatPaths } from "../../../firebase/chat/chatsPath";
import { doc, runTransaction, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import getChatRef from "../../../firebase/chat/getChatRef";

/**
 * Prepares new messages instance and upoads it to the firebase.
 * @param {*} text
 * @param {*} authorizedUser
 * @param {*} chatDocRef
 * @param {*} messages
 * @param {*} attachmentsArray
 */
export const sendMessage = async ( newMessage, authorizedUser, dialogue, selectedChatSnapId, unreadMessages) => {
  const chatRef = getChatRef(selectedChatSnapId)
  try {
    await runTransaction(
      firestore,
      async (transaction) => {
      // отрабатывает при каждой попытке.
        transaction.set(doc(chatRef, chatPaths.messagesSubCollection, newMessage.id), newMessage);
        transaction.update(chatRef, {lastEdited: serverTimestamp()});
        if(dialogue.assignedTo === authorizedUser.id) { // если назначенный визовик или супервизовик
          unreadMessages.forEach(message => {
            const messageDocRef = doc(firestore, `${chatPaths.chatCollection}/${selectedChatSnapId}/${chatPaths.messagesSubCollection}/${message.docId}`)
            transaction.update(messageDocRef, {
              readBy: [...message.readBy, authorizedUser.role],
              isReadFromCRM: true,
            });
          });
        }
      },
      {
        maxAttempts: 25
      }
    )
  } catch (error) {
    throw error
  }
};
