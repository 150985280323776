import React, {useState, useContext, memo, useLayoutEffect, useRef} from 'react';
import DialoguesListContainer from './DialoguesListContainer';
import Dialogue from './Dialogue';
import { UserDataContext } from '../../../contexts/context';
import getChatsQueryForDialoguesList from '../firebase/getChatsQueryForDialoguesList';
import { useEffect } from 'react';
import { getCountFromServer } from 'firebase/firestore';
import { useChatsContext, useChatSearchContext, useUpdateMessengerContext } from '../context/MessengerDataProvider';
import NewMessageNotificationService from '../../NotificationService/components/NewMessageNotificationService';
import Mask from '../../../components/Mask/Mask';

const Messenger = memo(({chatListOpen, dialogueWindowOpen, closeChatList, goToAppFromMessenger, openDialogue, loginTime}) => {
  // contexts
  const { authorizedUser } = useContext(UserDataContext);
  const { features } = useContext(UserDataContext);
  const { chatsData, chatsLoading, chatsError } = useChatsContext();
  const { chatsSearchFilter } = useChatSearchContext();
  const { setChatListPages } = useUpdateMessengerContext();
  const { setSelectedChat, setSelectedChatSnapId} = useUpdateMessengerContext()
  // states
  const [ showLoader, setShowLoader ] = useState(true);
  // refs
  const drawerBodyFirstChild = useRef(null); // ближайший способ добраться до body дравера - это получить ссылку на его первого ребенка и затем обратиться к parentNode.

  useEffect(() => {
    // Если все возможные чаты загружены - не показывать лоадер вконце списка и наоборот
    const checkAllChatsDownloaded = async () => {
      const aggregateSnapshot = await getCountFromServer(getChatsQueryForDialoguesList(authorizedUser, chatsSearchFilter));
      const isAllChatsDownloaded = aggregateSnapshot.data().count === chatsData.length;
      setShowLoader(!isAllChatsDownloaded)
    }
    checkAllChatsDownloaded();
  }, [chatsData]) // другие зависимости не добавлять, т.к. этот эффект должен выполниться только после изменения стейта данных.


  useLayoutEffect(() => {
    // 1. Получаем родителя - drawer body, т.к. именно он прокручивается в списке чатов мессенджера.
    // 2. Если drawer body доскроллен до низа - запрашиваем общее количество чатов.
    // 3. Если загружено не все - добавляем страницу, если загружено все - убираем loader внизу списка чатов
    if (chatListOpen) {
      const elem = drawerBodyFirstChild.current.parentNode;
      const onScroll = async () => {
        const scrolledToBottom = Math.abs(elem.scrollHeight - elem.clientHeight - elem.scrollTop) <= 1;
        if (scrolledToBottom) {
          const aggregateSnapshot = await getCountFromServer(getChatsQueryForDialoguesList(authorizedUser, chatsSearchFilter));
          const noMoreDataToDownload = aggregateSnapshot.data().count === chatsData.length;
          if (!noMoreDataToDownload) {
            setChatListPages(prev => ++prev);
          }
        }
      }
      elem.addEventListener('scroll', onScroll);
      return () => elem.removeEventListener('scroll', onScroll);
    }
  },[authorizedUser, chatListOpen, chatsData.length, chatsSearchFilter, setChatListPages])

  const closeMessenger = () => {
    setSelectedChat(null);
    setSelectedChatSnapId(null)
    closeChatList()
  }

  return (
    <>
      <Mask chatListOpen={chatListOpen} closeMessenger={closeMessenger}/>
      <DialoguesListContainer
        ref={drawerBodyFirstChild}
        chatListOpen={chatListOpen}
        closeChatList={closeChatList}
        openDialogue={openDialogue}
        showLoader={showLoader}
        closeMessenger={closeMessenger}
      />
      <Dialogue
        dialogueWindowOpen={dialogueWindowOpen}
        goToAppFromMessenger={goToAppFromMessenger}
      />
      {features.showNewMessageNotifications && <NewMessageNotificationService loginTime={loginTime.current} />}
    </>
  );
});

export default Messenger;
