import { Timestamp, serverTimestamp } from "firebase/firestore";
import GLOBAL_ROLES from "../../../constants/constants";

const combineNewUserData = (newOperatorFormValues, newUser) => {
  return {
    createdAt: serverTimestamp(),
    active: true,
    id: newUser.uid,
    name: `${newOperatorFormValues.name} ${newOperatorFormValues.surname}`,
    role: newOperatorFormValues.role,
    phoneNumber: newOperatorFormValues.tel,
    email: newUser.email,
    avatar:'',
    lastNewsViewTime: Timestamp.fromMillis(0),
  }
}

export default combineNewUserData;