import { collection, getDocs, query, Timestamp, where } from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { firestore } from '../../../firebase/firebase';
import dayjs from 'dayjs';
import useCompanyId from '../../../layout/hooks/useCompanyId';
import increaseDateByDays from '../../../utils/increaseDateByDays';

const useFinanceOperationsData = (rangeFilter) => {
  const  companyId  = useCompanyId();
  const [ operations, setOperations ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ operationsError, setOperationsError ] = useState(null);
  const upperLimitDate = useMemo(() => increaseDateByDays(rangeFilter[1], 1), [rangeFilter])

  const fetchOperations = useCallback(async () => {
    if(companyId) {
      try {
        setIsLoading(true)
        setOperationsError(null)
        const querySnap = await getDocs(query(
          collection(firestore, 'companies', companyId, 'financial_records'),
          where('createdAt', '>=', Timestamp.fromMillis(rangeFilter[0].valueOf() )),
          where('createdAt', '<', Timestamp.fromMillis(upperLimitDate.valueOf())),
        ));
        if(querySnap.empty) {
          setOperations([])
          return
        }
        setOperations(querySnap.docs.map(doc => {
          const operation = doc.data();
          const operationDate = dayjs(operation.createdAt.toMillis()).format('DD.MM.YYYY');
          return {
            date: operationDate,
            applicant: operation.applicantName,
            email: operation.applicantEmail,
            position: operation.position,
            operationType: operation.operationType,
            country: operation.countryNameRu,
            transactionAmount: operation.amount,
            id: operation.id,
            key: operation.id,
            balanceBefore: operation.balanceBefore,
            balanceAfter: operation.balanceAfter,
          }
        }));
      } catch (error) {
        console.log(error);
        setOperations([]);
        setOperationsError(error.message)
      } finally {
        setIsLoading(false)
      }
    }
  }, [companyId, rangeFilter, upperLimitDate])

  useEffect(() => {
    fetchOperations()
  }, [fetchOperations])


  return [
    operations,
    // balanceBefore,
    isLoading,
    operationsError,
    fetchOperations
  ]
};

export default useFinanceOperationsData;