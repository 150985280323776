import React,{useState, useContext, useRef } from 'react';
import { Input, Button, Modal } from 'antd';
import {SendOutlined } from "@ant-design/icons"
import ChatUpload from './upload/ChatUpload';
import { UserDataContext } from '../../../contexts/context';
import '../../../assets/chat/chat-footer.scss';
import SelectedAttachmentList from './upload/SelectedAttachmentList';
import AttachmentsPopupFooter from './upload/AttachmentsPopupFooter';
import { nanoid } from 'nanoid';

const { TextArea } = Input;

const ChatFooter = ({ dialogue, send}) => {
  const { authorizedUser, role } = useContext(UserDataContext)
  const [ modalIsOpened, setModalIsOpened ] = useState(false)
  const [ text, setText] = useState('');
  const [ attachmentText, setAttachmentText] = useState('')
  const [ chatUploadAttachmentList, setChatUploadAttachmentList ] = useState([])
  const storedTextRef = useRef('');

  const beforeUpload = (_attachment, newAttachmentList) => {
    setChatUploadAttachmentList([...chatUploadAttachmentList, ...newAttachmentList]);
    if(modalIsOpened === false) {
      if(text) {
        storedTextRef.current = text;
      }
      setModalIsOpened(true);
      setText('');
      setAttachmentText(text);
    }
    return false // прерываем дефолтную загрузку компонента
  }

  const handleModalCancel = () => {
    setModalIsOpened(false);
    setChatUploadAttachmentList([]);
    if(storedTextRef.current) {
      setText(storedTextRef.current)
    }
  }

  const handleSend = async () => {
    if(!text) return
    setText("")
    const messageId = nanoid();
    await send(messageId, text)
  }

  const handleSendWithAttachments = async () => {
    setModalIsOpened(false);
    setChatUploadAttachmentList([]);
    setAttachmentText("");
    storedTextRef.current = '';
    const messageId = nanoid();
    send(messageId, attachmentText, chatUploadAttachmentList)
  }

  const keyDownHandler = (e) => {
    if(e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault()
      handleSend();
    }
  }

  const attachmentTextChange = (e) => {
    setAttachmentText(e.target.value);
  }

  const handleTextChange = (e)=> {
    setText(e.target.value);
  }

  const inputAllowed =
    role === 'admin' ||
    role === 'superOperator'||
    role === 'careSupport'  ||
    dialogue.assignedTo === authorizedUser.id;

  return (
    <div className="chat__footer" >
      <div className="chat__upload-button">
        <ChatUpload
          disabled={!inputAllowed}
          beforeUpload={beforeUpload}
          chatUploadAttachmentList={chatUploadAttachmentList}
        />
      </div>
      <div className='chat__message-input' >
        {/* <TextAreaComp value={text} onChange={handleChange}/> */}
        <TextArea
          value={text}
          classNames={{textarea:'chat__textarea'}}
          autoSize={{minRows: 1, maxRows: 6}}
          placeholder='Напишите сообщение...'
          onKeyDown={keyDownHandler}
          disabled={!inputAllowed}
          onChange={handleTextChange}
        />
      </div>
      <div className='chat__send'>
        <Button
          className='chat__send-button'
          disabled={!inputAllowed}
          icon={<SendOutlined className='send-button__icon'/>}
          onClick={handleSend}
        />
      </div>
      <Modal
        title="Отправка файлов"
        centered={true}
        multiple
        open={modalIsOpened}
        onCancel={handleModalCancel}
        footer={(
          <AttachmentsPopupFooter
            handleSendWithAttachments={handleSendWithAttachments}
            beforeUpload={beforeUpload}
            chatUploadAttachmentList={chatUploadAttachmentList}
            handleModalCancel={handleModalCancel}
          />
        )}
      >
        <SelectedAttachmentList
          chatUploadAttachmentList={chatUploadAttachmentList}
        />
        <div style={{marginTop:"10px", borderBottom:"2px solid rgb(77, 161, 255)"}}>
          <Input
            placeholder="Текст сообщения"
            bordered={false}
            value={attachmentText}
            onChange={attachmentTextChange}
            onPressEnter={handleSendWithAttachments}
          />
        </div>
      </Modal>
    </div>
  )
};

export default ChatFooter;
