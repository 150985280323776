import { Button, Form, Modal, Tabs } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import MarkdownRenderer from '../../../components/MarkdownRenderer';
import AISecondaryRequestForm from './AISecondaryRequestForm';
import { UserDataContext } from '../../../contexts/context';
import { createMessageAtOpenAI, getMessagesByRunId, overCreateRun } from '../../../openai/openai';
import getCategories, { getCategoryLabel, getDefaultActiveTab, initialFetchedAnswers } from '../configs/request-categories-config';
import recreateThread from '../openai/recreateThread';

const AIRequestCategories = () => {
  const { authorizedUser } = useContext(UserDataContext);
  const [ activeTabKey, setActiveTabKey ] = useState(getDefaultActiveTab);
  const [ modalIsOpened, setModalIsOpened ] = useState(false);
  const [ fetchedAnswers, setFetchedAnswers ] = useState(initialFetchedAnswers);
  const [ answerIsLoading, setAnswerIsLoading ] = useState(false);
  const [ form ] = Form.useForm();

  const tabItems = useMemo(() => {
    return getCategories();
  }, [])

  const categoryLabel = useMemo(() => getCategoryLabel(activeTabKey), [activeTabKey])

  const changeTabHandler = (key) => {
    setActiveTabKey(key)
  }

  const openModalHandler = () => {
    setModalIsOpened(true)
  }

  const closeModalHandler = () => {
    setModalIsOpened(false);
  }

  const submitHandler = async () => {
    form.submit();
  }

  const FinishAiSecondaryForm = async (values) => {
    const requestText=values.requestText

    setAnswerIsLoading(true)
    try {
      const newThread = await recreateThread(authorizedUser.id, activeTabKey)
      await createMessageAtOpenAI(newThread.id, requestText);
      overCreateRun(
        {threadId: newThread.id, assistantId: 'asst_LRUex8rTNrrz9VwWePqqfIqi'},
        () => {},
        (e) => {
          setAnswerIsLoading(false)
          console.log(e)
        },
        async (run) => {
          const messages = await getMessagesByRunId(newThread.id, run.id);
          const AIanswer = messages.data[0].content[0].text.value
          setAnswerIsLoading(false)
          closeModalHandler()
          setFetchedAnswers(prev => ({...prev, [activeTabKey]:AIanswer}))
        })
    } catch (error) {
      setAnswerIsLoading(false)
    }
  }

  return (
    <>
      <Tabs
        activeKey={activeTabKey}
        items={tabItems}
        // type='card'
        hideAdd={true}
        centered
        onChange={changeTabHandler}
      />
      <div className='ai-assistant-form'>
        <Button
          onClick={openModalHandler}
        >
          Отправить запрос
        </Button>
        <MarkdownRenderer text={fetchedAnswers[activeTabKey] || ''}/>
        <Modal
          open={modalIsOpened}
          centered
          width={500}
          onCancel={closeModalHandler}
          onOk={submitHandler}
          confirmLoading={answerIsLoading}
          destroyOnClose={true}
          okButtonProps={{
            disabled: answerIsLoading
          }}
          cancelButtonProps={{
            disabled: answerIsLoading
          }}
        >
          <AISecondaryRequestForm
            form={form}
            answerIsLoading={answerIsLoading}
            FinishAiSecondaryForm={FinishAiSecondaryForm}
            categoryLabel={categoryLabel}
          />
        </Modal>
      </div>
    </>
  );
};

export default AIRequestCategories;