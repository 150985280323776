import { Button, Tag } from 'antd';
import React, { useContext } from 'react';
import { UserDataContext } from '../contexts/context';

const ApproveCompApplication = ({isApproved, handleButtonClick}) => {
  const { features } = useContext(UserDataContext)

  return (
    isApproved ? (
      <Tag style={{fontSize: '16px'}} color='#6DD697'>Списано</Tag>
    ) : (
      features.canApproveCompanyApplication ? (
        <Button onClick={handleButtonClick} type='primary'>Подтвердить</Button>
      ) : (
        <Tag style={{fontSize: '16px'}} color='#108ee9'>Не подтверждено</Tag>
      )
    )
  );
};

export default ApproveCompApplication;