const addPropertyBasedOnSelection = (countries, selectedCountriesFilter) => {
  if(!selectedCountriesFilter || selectedCountriesFilter.length === 0) return countries; // если selectedCountriesFilter: undefined | []
  let selectedCountriesCopy = [...selectedCountriesFilter]


  return countries.map(country => {
    if(selectedCountriesCopy.length) {
      const index = selectedCountriesCopy.findIndex((selectedCountry) => country.country_code === selectedCountry);
      if(index >= 0) { // если выбранная страна найдена - запишем в ее объект isSelected:true а из копии массива выбранных стран удалим найденную страну.
        selectedCountriesCopy = [...selectedCountriesCopy.slice(0,index), ...selectedCountriesCopy.slice(index+1)];
        return {...country, isSelected: true}
      }
    }
    return {...country}
  })
}

const getCountriesFilters = (countries, filters) => {
  if(!countries) return [];
  const updatedCountries = addPropertyBasedOnSelection(countries, filters?.countryFullName)

  const countriesList = updatedCountries.map(country => {
    return {
      text: country.name_ru,
      value: country.country_code,
      isSelected: !!country.isSelected,
    }
  })

  return countriesList.sort((a, b) => {
    const first = a.text.toLowerCase();
    const second = b.text.toLowerCase();
    if(a.isSelected && !b.isSelected) {
      return -1
    }
    if(b.isSelected && !a.isSelected) {
      return 1
    }
    if(first > second) return 1;
    if(first < second) return -1;
    return 0;
  })
}


export default getCountriesFilters;
