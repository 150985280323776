import { runTransaction, serverTimestamp } from "firebase/firestore";
import { createNewCompRef, getCompaniesCounterRef } from "../../../firebase/companies/companies";
import { firestore } from "../../../firebase/firebase";

// Создаем реф на новый документ коллекции debts
const createCompany = async (data) => {
  const companiesCounterRef = getCompaniesCounterRef();
  try {
    await runTransaction(firestore, async (transaction) => {
      const counterDoc = await transaction.get(companiesCounterRef);
      let newCount = 1;

      if (counterDoc.exists) {
        newCount = counterDoc.data().count + 1;
      }
      data.id = newCount.toString();
      transaction.set(companiesCounterRef, { count: newCount, changedAt: serverTimestamp()});
      const newCompanyRef = createNewCompRef(newCount);
      transaction.set(newCompanyRef, {...data});
    })
  } catch (error) {
    throw error;
  }
};

export default createCompany;