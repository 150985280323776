import React, { useMemo } from 'react';
import { useCompanyEmployees } from '../../../pages/CompanyProfile/providers/CompanyProvider';
import { Table } from 'antd';
import getEmployeesColumns from '../models/employeesTableColumns';
import Spinner from '../../../components/Spinner';

const CompanyEmployeeList = () => {
  const { employeesData, employeesLoading, employeesError } = useCompanyEmployees();
  const columns = useMemo(() => {
    return getEmployeesColumns();
  }, [])

  return (
    <div className='company-employee-list' style={{ overflowWrap:'break-word',whiteSpace:'pre-wrap'}}>
      {employeesError && <span style={{color:'red'}}>{employeesError}</span>}
      {employeesLoading && <Spinner />}
      {(employeesData && !employeesLoading && !employeesError ) && (
        <Table
          columns={columns}
          dataSource={employeesData}
          size='small'
          loading={employeesLoading}
          pagination={false}
          // rowClassName={getSectionsRowClass}
          // onChange={handleTableChange}
        />
      )}
    </div>
  );
};

export default CompanyEmployeeList;