import React, { useState, useContext } from 'react';
import { ProgramContext, UserDataContext } from '../../../contexts/context.js';
import MyError from '../../Error/components/MyError.jsx';
import ChatFooter from './ChatFooter.jsx';
import "../../../assets/chat/chat.scss";
import findUnreadMessages from '../helpers/findUnreadMessages.js';
import ChatMessages from './ChatMessages.jsx';
import { LayoutFuncsContext } from '../../../contexts/layoutContexts.js';
import editName from '../../../firebase/clients/editName.js';
import showNotification from '../../NotificationService/helpers/showNotification.js';
import ChatHeader from './ChatHeader.jsx';
import { sendMessage } from '../firebase/sendMessage.js';
import { createNewMessageObject } from '../helpers/createNewMessageObject.js';
import uploadAttachments from '../firebase/uploadAttachments.js';
import { prepareAttachmentsInfo } from '../helpers/prepareAttachmentsInfo.js';
import { useClientContext, useMessagesContext, useUnsentMessagesContext } from './ChatDataProvider.jsx';

const Chat = ({applicantId, source, dialogue, selectedChatSnapId}) => {
  // contexts
  const { authorizedUser } = useContext(UserDataContext);
  const { switchChatScrollMode } = useContext(LayoutFuncsContext);
  const { notificationAPI } = useContext(ProgramContext);
  const { messagesData, messagesLoading, messagesError, addTempMessage } = useMessagesContext();
  const { addUnsentMessage, removeUnsentMessage } = useUnsentMessagesContext()
  const { clientData, clientLoading, clientError } = useClientContext();

  // states
  const [ clientIsEditing, setClientIsEditing ] = useState(false);
  const [ sendMessageError, setSendMessageError ] = useState(undefined);
  // variables
  const unreadMessages = findUnreadMessages(messagesData);
  const client = clientData && clientData.data();

  const changeClientData = async (newData) => {
    try {
      setClientIsEditing(true)
      await editName(applicantId, newData)
      showNotification(notificationAPI, 'process', {processName: 'clientChanged', status: 'success'})
    } catch (error) {
      console.log(error);
      showNotification(notificationAPI, 'process', {processName: 'clientChanged', status: 'error'})
    } finally {
      setClientIsEditing(false)
    }
  }
  /**
   * Добавляем временное к текущему набору сообщений, пока сообщение не будет загружено в БД
   * @param {*} newMessageId
   * @param {*} text
   * @param {*} attachmentList
   */
  const send = async (newMessageId, text, attachmentList) => {
    let attachments = prepareAttachmentsInfo(attachmentList); // создаем аттачменты для сообщения без ссылок на их скачивание.
    const tempMessage = createNewMessageObject(text, authorizedUser, attachments, dialogue.assignedTo, dialogue.supportId, newMessageId, true); // создание временного сообщения отображаемого на фронте.
    try {
      addTempMessage(tempMessage);
      switchChatScrollMode(false);
      if(attachmentList?.length) {
        attachments = await uploadAttachments(attachments, attachmentList, applicantId); // загрузка аттачментов и внесение ссылок на их скачивание в уже созданный массив аттачментов.
      }
      const messageToUpload = createNewMessageObject(text, authorizedUser, attachments, dialogue.assignedTo, dialogue.supportId, newMessageId); // создание полностью сформированного сообщения.
      await sendMessage(messageToUpload, authorizedUser, dialogue, selectedChatSnapId, unreadMessages) // сохранение сообщения в чат в БД.
    } catch (error) {
      console.log(error.message)
      if(error.message === 'Connection failed.' || error.code === 'storage/retry-limit-exceeded') {
        const unsentMessage = {...tempMessage, isFailed: true, isTemporary: false, unsentAttachmentList: attachmentList}
        addUnsentMessage(applicantId, unsentMessage);
      }
      showNotification(notificationAPI, 'process', {processName: 'sendMessage', status: 'error'})
    }
  }

  if (messagesError) {
    return <MyError error={messagesError}/>
  }

  return (
    <div className="chat__container" >
      <ChatHeader
        dialogue={dialogue}
        clientData={client}
        applicantId={applicantId}
        source={source}
        selectedChatSnapId={selectedChatSnapId}
        changeClientData={changeClientData}
        clientIsEditing={clientIsEditing}
      />
      <ChatMessages
        assignedTo={dialogue.assignedTo}
        sendMessageError={sendMessageError}
        selectedChatSnapId={selectedChatSnapId}
        send={send}
      />
      <ChatFooter
        dialogue={dialogue}
        send={send}
      />
    </div>
  );
};

export default Chat;
