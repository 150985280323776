import * as renderers from '../utils/reconcilationStatementRenderes';

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    align: "center",
    // width: 70,
  },
  {
    title: 'Физ лицо',
    dataIndex: 'applicant',
    key: 'applicant',
    align: "center",
    // width: 70,
    render: 'renderEmployeeId',
    ellipsis: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: "center",
    // width: 70,
    // ellipsis: false,
  },
  {
    title: 'Должность',
    dataIndex: 'position',
    key: 'position',
    align: "center",
    // width: 70,
  },
  {
    title: 'Тип операции',
    dataIndex: 'operationType',
    key: 'operationType',
    align: "center",
    render: 'operationTypeRender'
    // width: 70,
  },
  {
    title: 'Страна',
    dataIndex: 'country',
    key: 'country',
    align: "center",
    // width: 70,
  },
  {
    title: 'Сумма операции',
    dataIndex: 'transactionAmount',
    key: 'transactionAmount',
    align: "center",
    // width: 70,
    // render:'renderEmployeesNumber'
  },
]

export default function getReconStatementColumns() {
  return columns.map(column => {
    if(typeof column.render === 'string') {
      return {
        ...column,
        render: renderers[column.render]
      }
    }
    return {...column}
  })
}