import { useCallback, useEffect, useState } from 'react';
import getClientsRef from '../../../firebase/clients/getClientsRef';
import { getDocs, orderBy, query, where } from 'firebase/firestore';
import dayjs from 'dayjs';

const useEmployeesData = (companyId) => {
  const [ employeesData, setEmployeesData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ employeesError, setEmployeesError ] = useState(null);
  const fetchEmployees = useCallback(async () => {
    if(companyId) {
      try {
        setEmployeesError(null)
        setIsLoading(true);
        const querySnap = await getDocs(query(getClientsRef(), where('company.id', '==', companyId), orderBy('company.addedAt')));
        if(!querySnap.docs.length) {
          return;
        }
        setEmployeesData(querySnap.docs.map(doc => {
          const userData = doc.data();
          const addedTime = dayjs(userData.company.addedAt.toMillis()).format('DD.MM.YY')
          return {
            id: userData.UID.slice(0,4),
            name: userData.name,
            phone: userData.phone,
            email: userData.email,
            addedTime: addedTime,
            docId: doc.id
          }
        }));
      } catch (error) {
        console.log(error);
        setEmployeesError(error.message)
      } finally {
        setIsLoading(false);
      }
    }
  },[companyId])

  useEffect(() => {
    fetchEmployees()
  }, [fetchEmployees])

  return [
    employeesData,
    isLoading,
    employeesError,
    fetchEmployees,
  ]
};

export default useEmployeesData;