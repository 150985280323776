import { getCompaniesQuery } from "../../../firebase/companies/companies";
import { useCallback, useEffect, useState } from "react";
import { getDocs } from "firebase/firestore";

const useCompaniesData = () => {
  const [ companiesData, setCompaniesData ] = useState([]);
  const [ companiesLoading, setCompaniesLoading ] = useState(true);
  const [ companiesError, setCompaniesError ] = useState(undefined);

  const getCollection = useCallback(async () => {
    try {
      setCompaniesLoading(true);
      const collSnap = await getDocs(getCompaniesQuery());
      if(!collSnap.empty) {
        setCompaniesData(collSnap.docs.map(doc => {
          const data = {...doc.data()}
          const name = `${data.name.organizationType} ${data.name.main} ${data.name.additionalDescription}`
          return {
            key: data.id,
            id: data.id,
            name: name,
            phone: data.phone,
            email: data.email,
            employeesNumber: data.employeesNumber,
            balance: data.balance,
            slug: data.slug,
          }
        }));
      }
    } catch (error) {
      console.log(error)
      setCompaniesError(error)
    } finally {
      setCompaniesLoading(false);
    }
  },[])

  useEffect(() => {
    getCollection();
  }, [getCollection])

  return [
    companiesData,
    companiesLoading,
    companiesError,
    getCollection,
  ]
};

export default useCompaniesData;