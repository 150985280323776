import { Button, DatePicker, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import getReconStatementColumns from '../models/reconciliationStatementColumns';
import { useBalanceBeforeAndResult, useFinanceOperations, useRangeFilter, useUpdateFinanceOperations } from '../providers/CompanyFinanceProvider';
import '../../../assets/company-finance/reconciliation-statement.scss';
import BalanceAddon from './BalanceAddon';
import { generatePDF } from '../../../jsPdf/reconciliation-report';
import { useCompany } from '../../../pages/CompanyProfile/providers/CompanyProvider';
import getReconciliationReportData from '../../../jsPdf/reconciliation-report/getReconciliationReportData';
const { RangePicker } = DatePicker;

const ReconciliationStatement = ({showCompanyFilter}) => {
  const { operationsData, operationsLoading, operationsError } = useFinanceOperations();
  const { companyData, companyLoading, companyError } = useCompany();
  const { updateRangeFilter } = useUpdateFinanceOperations();
  const { rangeFilter } = useRangeFilter();
  const {
    balanceBefore, balanceBeforeLoading, balanceBeforeError,
    balanceResult, balanceResultLoading, balanceResultError
  } = useBalanceBeforeAndResult()

  const handleRangeChange = (dates, dateStrings) => {
    updateRangeFilter(dates)
  }

  const columns = useMemo(() => {
    return getReconStatementColumns()
  },[])

  const handleCreatePdfClick = () => {
    const docData = getReconciliationReportData(companyData, rangeFilter, openingBalance, closingBalance, operationsData)
    generatePDF(docData);
  }

  const openingBalance = balanceBeforeLoading ? 'loading' : (balanceBefore?.resultBalance.KZT || 0);
  const closingBalance = balanceResultLoading ? 'loading' : (balanceResult?.resultBalance.KZT || 0);

  return (
    <div className='recon-statement company-finance__recon-statement'>
      <div className="recon-statement__before-table">
        <div className="recon-statement__tools">
          <div className="recon-statement__period-filter">
            <span className='period-filter__title'>{`Период:  `}</span>
            <RangePicker onChange={handleRangeChange} value={rangeFilter} format='DD.MM.YYYY'/>
          </div>
          <Button
            disabled={operationsLoading || companyLoading || operationsError || companyError || balanceBeforeLoading|| balanceBeforeError ||
              balanceResultLoading|| balanceResultError}
            onClick={handleCreatePdfClick}
            size='small'
            type='link'
            icon={<DownloadOutlined />}
          >
            Скачать pdf
          </Button>

        </div>
        <div className='recon-statement__balance-addon'>
          <BalanceAddon text='Сальдо' balance={openingBalance}/>
        </div>
        {showCompanyFilter ? (
          <div className="recon-statement__company-filter"></div>
        ) : null}
        {/* {operationsError && operationsError} */}
      </div>
      <Table
        loading={operationsLoading}
        columns={columns}
        dataSource={operationsData}
        size='small'
        pagination={false}
      />
      <div className="recon-statement__after-table">
        <div className='recon-statement__balance-addon'>
          <BalanceAddon text='Итог' balance={closingBalance}/>
        </div>
      </div>

    </div>
  );
};

export default ReconciliationStatement;