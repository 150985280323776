const getRoleRu = (role) => {
  const roles = {
    operator: 'Визовик',
    superOperator: 'Супервизовик',
    admin: 'Админ',
    companyManager: 'companyManager',
  }

  return roles[role];
}

export default getRoleRu;
