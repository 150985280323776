import {  collection, limit, orderBy, query } from "firebase/firestore";
import { chatPaths } from "../../../firebase/chat/chatsPath";
import { firestore } from "../../../firebase/firebase";

const getMessagesQuery = (chatDocId, page) => {
  if(!chatDocId) return null;
  const path = `${chatPaths.chatCollection}/${chatDocId}/${chatPaths.messagesSubCollection}`;

  const constraints = [
    orderBy('time', 'desc'),
  ]

  if(page) {
    constraints.push(limit(50 * page));
  }

  return query(
    collection(firestore, path),
    ...constraints,
  );
}

export default getMessagesQuery;