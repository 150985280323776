import { serverTimestamp, Timestamp } from "firebase/firestore";

export const createNewMessageObject = (text, authorizedUser, attachmentsArray = [], assignedTo, supportId,newMessageId, isTemporary) => {
  // при создании сообщения для временного сообщения на UI (которое еще не загружено в firestore) - используем локально созданный Timestamp.
  // при создании сообщения для отправки на сервер - указываем серверу создать timestamp самостоятельно (serverTimestamp)
  const time = isTemporary ? Timestamp.fromMillis(Date.now()) : serverTimestamp();
  const newMessage = {
    id: newMessageId,
    attachments: attachmentsArray,
    content: text,
    sendState: 0,
    readBy: [authorizedUser.role,],
    isReadFromCRM: assignedTo === authorizedUser.id,
    supportMessage: supportId === authorizedUser.id,
    assigneeMessage: assignedTo === authorizedUser.id,
    sender: authorizedUser.name,
    avatar: authorizedUser.avatar,
    time: time,
    type: "message",
  }
  if(isTemporary) {
    newMessage.isTemporary = true;
  }
  return newMessage
}
