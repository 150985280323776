import React, { useContext } from 'react';
import CompanyProvider, { useCompany } from '../providers/CompanyProvider';
import { Empty, Tabs, Typography } from "antd";
import CompanyInfo from '../../../modules/CompanyInfo/components/CompanyInfo';
import CompanyEmployeeList from '../../../modules/CompanyEmployeeList/components/CompanyEmployeeList';
import NewEmployee from '../../../modules/NewEmployee/components/NewEmployee';
import CompanyTitle from '../../../components/CompanyTitle';
import CompanyFinance from '../../../modules/CompanyFinance/components/CompanyFinance';
import CompanyApplications from '../../../modules/CompanyApplications/components/CompanyApplications';
import { UserDataContext } from '../../../contexts/context';
import useCompanyId from '../../../layout/hooks/useCompanyId';
import CompanyFinanceProvider from '../../../modules/CompanyFinance/providers/CompanyFinanceProvider';
import CompanyManager from '../../../modules/CompanyManager.jsx/components/CompanyManager';

const companyTabItems = [
  {
    key:'companyInfo',
    label:'Профиль',
    children: (
      <div style={{display:'flex',justifyContent: 'center', gap: '3rem', backgroundColor:'white', padding:'1rem'}}>
        <CompanyInfo />
        <CompanyManager />
      </div>
    )
  },
  {
    key:'applications',
    label:'Заявки сотрудников',
    children: (
      <CompanyApplications />
    )
  },
  {
    key:'companyEmployees',
    label:'Сотрудники',
    children:(
      <>
        <CompanyEmployeeList />
        <NewEmployee />
      </>
    )
  },
  {
    key:'companyFinance',
    label:'Акт сверки',
    children: (
      <CompanyFinanceProvider>

        <CompanyFinance />
      </CompanyFinanceProvider>
    )
  },
]
const getItems = () => {
  return companyTabItems;
}

const CompanyPage = () => {
  const companyId = useCompanyId()
  if (companyId === null) {
    return (
      <div style={{height:'100%', display:'flex', alignItems:'center'}}>
        <Empty
          style={{width:'100%'}}
          description={
            <Typography.Text>
              Вы не прикреплены к компании
            </Typography.Text>
          }
        />
      </div>

    )
  }
  return (
    <CompanyProvider companyId={companyId}>
      <CompanyFinanceProvider>
        <div className='company'>
          <CompanyTitle />
          <Tabs
            items={getItems()}
            centered
            />
        </div>
      </CompanyFinanceProvider>
    </CompanyProvider>
  );
};

export default CompanyPage;