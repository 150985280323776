import ServiceMessageNotificationDescription from "../components/ServiceMessageNotificationDescription";
import getNotificationConfig from "./getNotificationConfig";

const processDirectory = {
  sendMessage:{
    error: {
      title: "Произошла ошибка!",
      description: "При отправке сообщения произошла ошибка!",
    }
  },
  operatorActivate: {
    success: {
      title: "Операция выполнена успешно!",
      description: "Аккаунт включен!",
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при активации аккаунта',
    }
  },
  opeartorDeactivate:{
    success: {
      title: "Операция выполнена успешно!",
      description: "Аккаунт отключен!",
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при отключении аккаунта',
    },
    // warning: {
    //   title: "Внимание!",
    //   description: "Пожалуйста, сообщите об удалении визовика разработчику!",
    // },
  },
  createNewOperator:{
    success: {
      title: "Операция выполнена успешно!",
      description: "Аккаунт визовика успешно создан!",
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при создании нового аккаунта!',
    },
  },
  reassignOperator: {
    success: {
      title: "Операция выполнена успешно!",
      description: "Все заявки и/или чат успешно переназначены!",
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при переназначении визовика!',
    },
  },
  assignAssistant: {
    success: {
      title: "Операция выполнена успешно!",
      description: "Ассистент успешно назначен!",
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при назначении ассистента!',
    },
  },
  updateAdmin: {
    success: {
      title: "Операция выполнена успешно!",
      description: "Данные профиля успешно изменены!",
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка изменении данных профиля!',
    },
  },
  uploadFile:{
    success: {
      title: "Операция выполнена успешно!",
      description: 'Файл(ы) успешно загружен(ы)',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Недопустимый формат или размер файла. Допустима загрузка файлов только в формате ".pdf или ".jpg" размером не более 30 мб '
    },
  },
  deleteUploadedFile: {
    success: {
      title: "Операция выполнена успешно!",
      description: 'Файл успешно удален!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'При удалении файла произошла ошибка!',
    },
  },
  dataUpdated: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Данные успешно обновлены!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'При обновлении данных произошла ошибка!'
    },
  },
  operatorChanged: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Визовик успешно изменен!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при обновлении визовика!'
    },
  },
  clientChanged: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Клиент успешно изменен!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при обновлении клиента!'
    },
  },
  statusChanged: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Статус заявки успешно обновлен!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при обновлении статуса заявки! '
    },
  },
  statusChangeAllowance: {
    error: {
      title: 'В смене статуса отказано!',
      description: 'Вы не можете вернуть заявку в работу, если у клиента есть заявки, обрабатываемые другим визовиком!',
    }
  },
  changeDocSectionStatus: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Статус секции документов успешно обновлен!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'Произошла ошибка при обновлении статуса секции документов! '
    },
  },
  noLongerAssignedTo: {
    warning: {
      title: 'Внимание!',
      description: 'Заявка назначена на другого визовика!',
    },
  },
  news: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Новость успешно сохранена!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'При сохранении новости произошла ошибка!'
    }
  },
  pushes: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Уведомление успешно создано!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'При создании уведомления произошла ошибка!'
    }
  },
  assignIssue: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Обрещние в работе!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'При назначении обращения произошла ошибка!'
    }
  },
  finishIssue: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Обрещние за помощью успешно разрешено!',
    },
    error: {
      title: 'Произошла ошибка!',
      description: 'При завершении обращения за помощью произошла ошибка!'
    }
  },
  employeeIsBusy: {
    error: {
      title: 'Отклонено!',
      description: 'Выбранный сотрудник уже занят этим клиентом! Пожалуйста, выберите другого сотрудника'
    }
  },
  reloadIncoming: {
    warning: {
      title: 'Внимание!',
      description: 'Через 5 минут произойдет перезагрузка СРМ, все несохраненные данные будут утеряны!',
    }
  },
  changePrice: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Стоиость успешно изменена!',
    },
    error: {
      title: 'Ошибка!',
      description: 'Произошла ошибка при изменении цены',
    },
  },
  noPermission: {
    error: {
      title: 'Ошибка доступа!',
      description: 'Нет прав для данной операции',
    }
  },
  newCompany: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Новая компания успешно зарегистрирована!',
    },
    error: {
      title: 'Ошибка!',
      description: 'Произошла ошибка при регистрации компании',
    },
  },
  replenishCompanyAccount: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Баланс успешно пополнен!',
    },
    error: {
      title: 'Ошибка!',
      description: 'Ошибка при пополнении баланса!',
    },
  },
  approveApplication: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Заявка успешно одобрена!',
    },
    error: {
      title: 'Ошибка!',
      description: 'Ошибка при одобрении заявки!',
    },
  },
  addCompanyManager: {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Менеджер успешно добавлен!',
    },
    error: {
      title: 'Ошибка!',
      description: 'Ошибка при добавлении менеджера!',
    },
  },
  addCompanyEmployee : {
    success: {
      title: 'Операция выполнена успешно!',
      description: 'Сотрудник успешно добавлен!',
    },
    error: {
      title: 'Ошибка!',
      description: 'Ошибка при добавлении сотрудника!',
    },
  }
}

const showOperationStatusNotification = (notificationAPI, options) => {
  const {processName, status, data, key } = options;
  const config = getNotificationConfig('process');
  const message = processDirectory[processName][status].title;
  const description = <ServiceMessageNotificationDescription processName={processName} description={processDirectory[processName][status].description} data={data}/>
  notificationAPI[status]({...config, key, message, description});
}

export default showOperationStatusNotification;
