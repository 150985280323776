import React from 'react';
import "../../../assets/chat/message.scss";
import getAttachments from '../helpers/getAttachments';
import { RedoOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Message = ({styleClass, message, time, attachmentsIsLoading=false, send}) => {
  const attachments = message.attachments.length > 0 ? getAttachments(message.attachments, attachmentsIsLoading) : null;
  const senderName = message.sender !== "me"
    ? (
        <div className="message__senderName">
          {message.sender}
        </div>
      )
    : null

  const handleRetrySendMessage = () => {
    send(message.id, message.content, message.unsentAttachmentList);
  }

  return (
    <li className="message__container">
      <div className={styleClass}>
        <div className='message__title'>
          {senderName}
          {/* <div className='message-menu'>
            <div className='message-menu__dot'></div>
            <div className='message-menu__dot'></div>
            <div className='message-menu__dot'></div>
          </div> */}
        </div>
        {attachments}
        <p className="message__text">
          {message.content || <i>пустое сообщение</i>}
        </p>
        <div className="message__status">
          {message.isTemporary && !message.isFailed ? (
            <Spin size='small' style={{}}></Spin>
          ): null}
          <span className="message__created-time">{time}</span>
          {message.isFailed ? (
            <RedoOutlined style={{fontSize:'16px', color:"rgb(224, 66, 66)", cursor: 'pointer'}} onClick={handleRetrySendMessage}/>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default Message;

