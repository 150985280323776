import React, { useContext} from 'react';
import { Upload } from "antd";
import { PaperClipOutlined } from "@ant-design/icons"
import { ApplicationStatus } from '../../../../contexts/context';

const ChatUpload = ({disabled, beforeUpload, chatUploadAttachmentList}) => {
  const { curAppStatus } = useContext(ApplicationStatus);

  return (
    <Upload
      disabled={disabled}
      showUploadList={false}
      beforeUpload={beforeUpload}
      fileList={chatUploadAttachmentList}
      multiple
    >
      <PaperClipOutlined
        className={curAppStatus > 4 ? "interactive-icons interactive-icons-disabled" : "interactive-icons"}
        style={{fontSize:"24px", marginRight: "5px"}}
      />
    </Upload>
  );
};

export default ChatUpload;
