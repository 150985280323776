import { firestore } from "../firebase";
import { collection, doc, Firestore, orderBy, query, runTransaction, serverTimestamp } from "firebase/firestore";

// const companyPaths = {
//   companiesCollection: "companies",
// }
const COMPANIES_COLLECTION = "companies";
const COMPANIES_COUNTER_PATH = 'counters/companies';

export const createNewCompRef = (docId) => {
  return doc(firestore, `${COMPANIES_COLLECTION}/${docId}`)
}

export const getCompaniesCounterRef = () => {
  return doc(firestore, COMPANIES_COUNTER_PATH);
}

export const getCompanyRef = (companyId) => {
  return doc(firestore, `${COMPANIES_COLLECTION}/${companyId}`);
}

export const getCompaniesQuery = () => {
  return query(collection(firestore, COMPANIES_COLLECTION), orderBy('createdAt', 'desc'));
}

export const getBalanceHistoryRef = (companyId) => {
  return collection(firestore, 'companies', companyId, 'balance_history');
}

export const approveApplication = async (record) => {
  try {
    const appRef = doc(firestore, 'applications', record.docId);
    const companyRef = doc(firestore, 'companies', record.application.companyId);
    const newHistroyDocRef = doc(collection(companyRef, 'financial_records'));
    const newBalanceHistroyDocRef = doc(getBalanceHistoryRef(record.application.companyId))

    await runTransaction(firestore, async (transaction) => {
      const companyDoc = await transaction.get(companyRef);
      const companyData = companyDoc.data();

      if (!companyDoc.exists()) {
        throw new Error("Document does not exist!");
      }
      const updatedBalance = companyData.balance.KZT - record.application.price_USD;
      transaction.update(appRef, { // подтверждаем заявку, добавляем доту подтверждения.
        isApproved: true,
        approvedAt: serverTimestamp(),
      }).update(companyRef, { // вычитаем из баланса стоимость заявки
        balance: {
          ...companyData.balance,
          KZT: updatedBalance,
        }
      }).set(newHistroyDocRef, { // добавляем запись в финансовой истории компании
        id: newHistroyDocRef.id,
        applicantName: record.application.applicantName,
        applicantEmail: record.application.applicantEmail,
        countryCode: record.application.country_code,
        countryNameRu: record.application.country_name_ru,
        createdAt: serverTimestamp(),
        // balanceBefore: companyData.balance.KZT,
        amount: record.application.price_USD,
        // balanceAfter: updatedBalance,
        operationType: 'write-off', // 'write-off', 'replenishment'
      }).set(newBalanceHistroyDocRef, {
        createdAt: serverTimestamp(),
        balanceBefore: companyData.balance,
        amount: record.application.price_USD,
        operationType: 'write-off',
        resultBalance: {
          ...companyData.balance,
          KZT: updatedBalance,
        },
      })
    });
  } catch (error) {
    console.log(error)
  }
}

export const replenishAccount = async (companyId, amount, authorizedUser) => {
  try {
    const companyRef = getCompanyRef(companyId);
    const historyRef = collection(companyRef, 'financial_records');
    const newHistroyDocRef = doc(historyRef);
    const newBalanceHistroyDocRef = doc(getBalanceHistoryRef(companyId))

    await runTransaction(firestore, async (transaction) => {
      const companyDoc = await transaction.get(companyRef);
      const balance = companyDoc.get('balance');
      const newBalance = {...balance, KZT: balance.KZT + +amount};
      transaction.update(companyRef, {balance: newBalance});
      transaction.set(newHistroyDocRef, { // добавляем запись в финансовой истории компании
        id: newHistroyDocRef.id,
        applicantName: authorizedUser.name,
        applicantEmail: authorizedUser.email,
        // countryCode: record.application.country_code,
        // countryNameRu: record.application.country_name_ru,
        createdAt: serverTimestamp(),
        balanceBefore: balance.KZT,
        amount: +amount, // переименовать в amount
        balanceAfter: balance.KZT + +amount,
        operationType: 'replenishment', // 'write-off', 'replenishment'
      }).set(newBalanceHistroyDocRef, {
        createdAt: serverTimestamp(),
        balanceBefore: balance,
        amount: amount,
        operationType: 'replenishment',
        resultBalance: newBalance,
      })
    })

  } catch (error) {

  }
}