import { serverTimestamp } from 'firebase/firestore';

const createCompanyData = (formValues) => {
  const companyData = {
    createdAt: serverTimestamp(),
    actualAdress: formValues.actualAdress,
    legalAdress: formValues.legalAdress,
    BIN: formValues.BIN,
    name: {
      organizationType: formValues.orgType,
      main: formValues.mainName,
      additionalDescription: formValues.additionalDescription || '',
    },
    phone: formValues.phone,
    email: formValues.email,
    id: undefined, // будет присвоен дальше, после получения данных счетчика.
    employeesNumber: 0,
    balance: {
       KZT: 0,
       USD: 0,
    },
    manager: {
      id: '',
      phone: '',
      email: '',
      name: '',
    },
  }
  return companyData;
};

export default createCompanyData;