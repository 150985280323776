import { doc, runTransaction } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { chatPaths } from "../../../firebase/chat/chatsPath";

const readUnreadMessages = async (unreadMessages, role, selectedChatSnapId) => {
  await runTransaction(firestore, async (transaction) => {
    unreadMessages.forEach(message => {
      const messageDocRef = doc(firestore, `${chatPaths.chatCollection}/${selectedChatSnapId}/${chatPaths.messagesSubCollection}/${message.docId}`);
      transaction.update(messageDocRef, {readBy: [...message.readBy, role], isReadFromCRM: true});
    });
  })
};

export default readUnreadMessages;