import {createContext} from "react";

export const ProgramContext = createContext('!!no context provider!!')
export const ApplicationStatus = createContext('!!no context provider!!')
export const ProfileContext = createContext('!!no context provider!!')
export const PassportInfoContext = createContext('!!no context provider!!')
export const ApplicantPassportContext = createContext('!!no context provider')
export const OperatorsContext = createContext('!!no context provider')
export const QuestionnaireContext = createContext('!!no context provider')
export const UserDataContext = createContext('!!no context provider')
export const DocsContentContext = createContext('!!no context provider')
export const DocSectionContext = createContext('!!no context provider')
export const IssuesContext = createContext('!!no context provider')
export const MessengerContext = createContext('!!no context provider')
export const VersionCheckContext = createContext('!!no context provider')
export const ReloadTimerContext = createContext('!!no context provider')


