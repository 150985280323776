export const getClassNameForMessage = (sender, isFailed) => {
  let className = 'message__content ';
  if(sender === "me") {
    className += 'applicant '
  } else {
    className += 'operator '
  }
  if(isFailed) {
    className += 'message__content_send-failed '
  }
  return className;
}
