import React, { useState } from 'react';
import { useCompany } from '../../../pages/CompanyProfile/providers/CompanyProvider';
import '../../../assets/company-info/company-info.scss'

const items = [
  {
    key: 'name',
    label: 'Название ТОО'
  },
  {
    key: 'phone',
    label: 'Телефон'
  },
  {
    key: 'email',
    label: 'Email'
  },
  {
    key: 'actualAdress',
    label: 'Фактический адрес'
  },
  {
    key: 'legalAdress',
    label: 'Юридический адрес'
  },
  {
    key: 'BIN',
    label: 'БИН'
  },
  {
    key:'employeesNumber',
    label:'Кол-во сотрудников'
  }
]

const getCompanyInfoItems = (companyData) => {
  return items.map(item => {
    let itemData = companyData[item.key]
    if(item.key === 'name') {
      itemData = `${companyData.name.organizationType} ${companyData.name.main} ${companyData.name.additionalDescription}`
    }
    return (
      <div className='company-info__item' key={item.key}>
        <span className='company-info__label'>{`${item.label}: `}</span>
        <span>{itemData}</span>
      </div>
    )
  })
}

const CompanyInfo = () => {
  const { companyData, companyLoading, companyError } = useCompany();
  const [ isEdit, setIsEdit ] = useState(false);

  return (
    <div className='company-info'>
      {companyLoading ? "Загрузка..." : getCompanyInfoItems(companyData)}
    </div>
  );
};

export default CompanyInfo;