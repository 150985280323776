import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useCompanyData from '../hooks/useCompanyData';
import { useParams } from 'react-router-dom';
import useEmployeesData from '../hooks/useEmployeesData';
import useCompanyApplicationsData from '../hooks/useCompanyApplicationsData';
import useCompanyId from '../../../layout/hooks/useCompanyId';
import useCompanyManagerData from '../hooks/useCompanyManagerData';

const CompanyDataContext = createContext({});
const CompanyEmployeesContext = createContext({});
const UpdateCompanyContext = createContext({});
const CompanyApplicationsDataContext = createContext({});
const CompanyManagerContext = createContext({});

const CompanyProvider = ({companyId, children}) => {
  const [ companyData, companyLoading, companyError, companyReload ] = useCompanyData(companyId);
  const [ employeesData, employeesLoading, employeesError, reloadEmployees ] = useEmployeesData(companyId);
  const [ applications, applicationsLoading, applicationsError, reloadApplications ] = useCompanyApplicationsData(companyId);
  const [ companyManager, companyManagerLoading, companyManagerError ] = useCompanyManagerData(companyData?.manager?.id);

  const companyApplicationsDataContext = useMemo(() => {
    return {
      applications,
      applicationsLoading,
      applicationsError
    }
  }, [applications, applicationsError, applicationsLoading])

  const companyDataContext = useMemo(() => {
    return {
      companyData,
      companyLoading,
      companyError,
    }
  }, [companyData, companyError, companyLoading])

  const companyEmployeesContext = useMemo(() => {
    return {
      employeesData,
      employeesLoading,
      employeesError
    }
  }, [employeesData, employeesError, employeesLoading])

  const updateCompanyContext = useMemo(() => {
    return {
      companyReload,
      reloadEmployees,
      reloadApplications,
    }
  }, [companyReload, reloadEmployees, reloadApplications])

  const companyManagerContext = useMemo(() => {
    return {
      companyManager, companyManagerLoading, companyManagerError
    }
  }, [companyManager, companyManagerError, companyManagerLoading])

  return (
    <CompanyDataContext.Provider value={companyDataContext}>
      <CompanyEmployeesContext.Provider value={companyEmployeesContext}>
        <UpdateCompanyContext.Provider value={updateCompanyContext}>
          <CompanyApplicationsDataContext.Provider value={companyApplicationsDataContext}>
            <CompanyManagerContext.Provider value={companyManagerContext}>
              {children}
            </CompanyManagerContext.Provider>
          </CompanyApplicationsDataContext.Provider>
        </UpdateCompanyContext.Provider>
      </CompanyEmployeesContext.Provider>
    </CompanyDataContext.Provider>
  )
};

export default CompanyProvider;

export const useCompany = () => useContext(CompanyDataContext)
export const useCompanyEmployees = () => useContext(CompanyEmployeesContext)
export const useCompanyApplications = () => useContext(CompanyApplicationsDataContext)
export const useUpdateCompany = () => useContext(UpdateCompanyContext)
export const useCompanyManager = () => useContext(CompanyManagerContext)
