const requestCategories = [
  {
    key: 'case-1',
    label: 'Purpose of Visit'
  },
  {
    key: 'case-2',
    label: 'Suggested Questions'
  },
  {
    key: 'case-3',
    label: 'List of Documents'
  },
  {
    key: 'case-4',
    label: 'Step by Step'
  },
  {
    key: 'case-5',
    label: 'Other Options'
  },
  {
    key: 'case-6',
    label: 'Useful links'
  }
]

export const getCategories = () => {
  return requestCategories;
}

export const getDefaultActiveTab = () => {
  return requestCategories[0].key;
}

export const getCategoryLabel = (key) => {
  const tabModel = requestCategories.find(tabModel => tabModel.key === key)
  return tabModel.label;
}

export const initialFetchedAnswers = () => {
  return requestCategories.reduce((acc, category, index) => {
    acc[category.key] = undefined
    return acc;
  }, {})
}

export default getCategories;