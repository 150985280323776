import { createTestThread, deleteThread } from "../../../openai/openai";

const recreateThread = async (authorizedUserId, activeTabKey) => {
  const threadsInTabs = JSON.parse(localStorage.getItem(`${authorizedUserId}-ai-tabs`));
    if(threadsInTabs) {
      const prevThreadId = threadsInTabs[activeTabKey];
      if(prevThreadId) {
        const deletionStatus = await deleteThread(prevThreadId);
        if(deletionStatus.deleted) console.log(`Thread ${deletionStatus.id} deleted`);
      }
    }
  const newThread =  await createTestThread()
  localStorage.setItem(`${authorizedUserId}-ai-tabs`, JSON.stringify({...threadsInTabs, [activeTabKey]: newThread.id}));
  return newThread
};

export default recreateThread;