import { Input, Select } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserDataContext } from '../../../contexts/context';
import { AppsSearchFiltersContext, LayoutFuncsContext } from '../../../contexts/layoutContexts';
import infoContents from '../../../components/PopoverInfo/infoContents';
import PopoverInfo from '../../../components/PopoverInfo/PopoverInfo';
import getSearchOptions, { getDefaultSearchOption } from '../config/searchConfig';
const { Search } = Input;

const HeadSearch = () => {
  const { role } = useContext(UserDataContext);
  const location = useLocation();
  const { searchFilters } = useContext(AppsSearchFiltersContext);
  const [ selectedSearchOption, setSelectedSearchOption ] = useState(searchFilters?.selectedSearchOption || null);
  const [ searchBarStatus, setSearchBarStatus ] = useState('');
  const [ appsSearchValue, setAppsSearchValue ] = useState(searchFilters?.appsSearchValue || '');
  const { resetAppsPageNum, saveSearchFilters } = useContext(LayoutFuncsContext);

  useEffect(() => {
    setSelectedSearchOption(getDefaultSearchOption(location.pathname))
    saveSearchFilters(null)
    setAppsSearchValue('')
    setSelectedSearchOption(getDefaultSearchOption(location.pathname))
  }, [location.pathname, saveSearchFilters])

  const handleSearch = useCallback(() => {
    // вешаем ошибку если есть пустое поле
    if((!selectedSearchOption || !appsSearchValue) && !searchBarStatus) {
      setSearchBarStatus('error');
      return;
    }
    // снимаем ошибку
    if(searchBarStatus && appsSearchValue && selectedSearchOption) {
      setSearchBarStatus('')
    }

    resetAppsPageNum();
    const searchFilters = {
      selectedSearchOption,
      appsSearchValue,
      searchPage: location.pathname,
    }
    saveSearchFilters(searchFilters)
  },[appsSearchValue, location.pathname, resetAppsPageNum, saveSearchFilters, searchBarStatus, selectedSearchOption])

  const handleSearchOptionChange = useCallback((selectedValue, _option) => {
    if(selectedSearchOption === selectedValue) return; //
    if(selectedValue === 'phone') {
      setAppsSearchValue(prev => '+' + prev)
    } else {
      setAppsSearchValue(prev => /^\+{1}/.test(prev) ? prev.slice(1) : prev)
    }
    if(selectedValue === 'empty') {
      // сброс типа поиска
      setSelectedSearchOption(null);
      const newFilters = {appsSearchValue: '', selectedSearchOption: null} // обязательнно сбросить глобальный стейт полностью, чтобы не было ошибок при загрузке
      saveSearchFilters(newFilters);
      resetAppsPageNum();
      return;
    }

    setSelectedSearchOption(selectedValue);
  },[resetAppsPageNum, saveSearchFilters, selectedSearchOption])

  const handleValueChange = useCallback((e) => {
    if(!selectedSearchOption) return;
    if(!e.target.value) {    // сброс строки
      if(selectedSearchOption === 'phone') { // тип поиска - номер телефона
        if(appsSearchValue === '+') return; // ничего не делаем
        setAppsSearchValue('+');
      } else {  // тип поиска - иное
        if(!appsSearchValue) return // ничего не делаем
        setAppsSearchValue(e.target.value);
      }
      const newFilters = {selectedSearchOption: null, appsSearchValue: ''} // обязательнно сбросить глобальный стейт полностью, чтобы не было ошибок при загрузке
      saveSearchFilters(newFilters);
      resetAppsPageNum();
      return
    }
    setAppsSearchValue(e.target.value);
  },[appsSearchValue, resetAppsPageNum, saveSearchFilters, selectedSearchOption])

  const options = getSearchOptions(location, role);

  return (
    <div className='apps-search-bar__container'>
      <Search
        addonBefore={(
          <Select
            value={selectedSearchOption}
            options={options}
            placeholder={searchBarStatus ? 'Выберите' : 'Фильтр'}
            style={{
              width: 130,
            }}
            variant='borderless'
            // bordered={false}
            onSelect={handleSearchOptionChange}
          />
        )}
        allowClear={true}
        size='large'
        value={appsSearchValue}
        status={searchBarStatus}
        placeholder={searchBarStatus ? 'Заполните поле' : 'Введите значение'}
        onChange={handleValueChange}
        onSearch={handleSearch}
        disabled={selectedSearchOption === null}
      />
      <PopoverInfo text={infoContents.headSearch}/>
    </div>
  );
};

export default HeadSearch;