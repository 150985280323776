import React, { useContext } from 'react';
import AccessDenied from './pages/AccessDenied/components/AccessDenied';
import AuthRoutes from './AuthRoutes';
import WorkRoutes from './WorkRoutes';
import { UserDataContext } from './contexts/context';

const AppRouter = () => {
  const { user, authorizedUser } = useContext(UserDataContext);
  if (!user) {
    return <AuthRoutes />
  }

  if (!authorizedUser || !authorizedUser.active) {
    return <AccessDenied />
  }

  return <WorkRoutes />
};

export default AppRouter;
