import React from 'react';
import '../../../assets/components/balance-addon.scss';

const BalanceAddon = ({text, balance}) => {
  return (
    <div className="balance-addon">
      <span className='balance-addon__label'>{`${text}:`}</span>
      <span className='balance-addon__balance'>{balance !== null ? `${balance} KZT` : ''}</span>
    </div>
  );
};

export default BalanceAddon;