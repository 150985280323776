import React, {useState, useEffect, useContext, memo, useCallback, useMemo} from "react";
import {Breadcrumb, Button, Table} from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDocumentData } from "react-firebase-hooks/firestore";
import { UserDataContext } from "../../../contexts/context.js";
import getAllCountriesRef from "../../../firebase/countries/getAllCountriesRef.js";
import Paginator from '../../../ui/Paginator.jsx';
import MyError from "../../Error/components/MyError.jsx";
import getTableData from "../helpers/getTableData.js";
import ColumnSelector from "../../../components/ColumnSelector/ColumnSelector.jsx";
import { getColumnsConfig, getDefaultSetOfColumns, setOfColumns } from "../config/table-columns-config.jsx";
import saveFilterToLocalStorage from "../localstorage/saveFilterToLocalStorage.js";
import getLocalStorageValue from "../localstorage/getLocalStorageValue.js";
import useApplications from "../hooks/useApplications.js";
import removeFilterIfColumnDeselected from "../localstorage/removeFilterIfColumnDeselected.js";
import saveColumnsToLocalStorage from "../localstorage/saveColumnsToLocalStorage.js";
import useEmployeesPartialData from "../../../useEmployeesPartialData.js";
import { AppsPagesContext, LayoutFuncsContext } from "../../../contexts/layoutContexts.js";
import '../../../assets/applications/applications.scss'


const handleRowClassName = (record, index) => {
  let rowClass = ''
  if(record.accountIsDeleted) rowClass += ' is-deleted';
  if(!record.application.isRead) rowClass += ' is-new';
  return rowClass;
}

const getDefaultFilters = (authorizedUserId, filtersType) => {
  return {
    status: getLocalStorageValue(authorizedUserId, 'status', filtersType),
    countryFullName: getLocalStorageValue(authorizedUserId, 'countryFullName', filtersType),
    payment: getLocalStorageValue(authorizedUserId, 'payment', filtersType),
    assignedTo: getLocalStorageValue(authorizedUserId, 'assignedTo', filtersType),
  }
}

const ALL_COUNTRIES_REF = getAllCountriesRef();

const Applications = memo(({clientUID}) => {
  // contexts
  const { authorizedUser, role } = useContext(UserDataContext);
  const { pageCount } = useContext(AppsPagesContext);
  const { addAppsPage, resetAppsPageNum } = useContext(LayoutFuncsContext);
  // states
  const tableFiltersType = clientUID ? 'clientApplicationFilters' : 'applicationFilters';
  const columnsType = clientUID ? 'clientColumnsToDisplay' : 'columnsToDisplay';
  const [ tableFilters, setTableFilters ] = useState(() => getDefaultFilters(authorizedUser.id, tableFiltersType));
  const [ columnsToDisplay, setColumnsToDisplay ] = useState (() => setOfColumns(authorizedUser, columnsType));
  const [ tableData, setTableData ] = useState([]);
  // download data
  const [ countriesData, countriesLoading, countriesError, countriesDocSnapshot ] = useDocumentData(ALL_COUNTRIES_REF);
  const [ applicationsData, totalCount, tableLoading, tableError, applicationsCollSnap ] = useApplications(authorizedUser, tableFilters, clientUID, pageCount);
  const [ employeesData, employeesLoading, employeesError ] = useEmployeesPartialData(false);

  const columns = useMemo(() => {
    const countries = countriesLoading ? [] : countriesData.countries;
    return  getColumnsConfig(columnsToDisplay, tableFilters, countries, employeesData);
  }, [columnsToDisplay, countriesData?.countries, countriesLoading, employeesData, tableFilters])

  useEffect(() => {
    if(applicationsData) {
      if(!applicationsCollSnap || (applicationsCollSnap && !applicationsCollSnap.metadata.fromCache)) {
        setTableData(getTableData(applicationsData));
        return
      }
    }
  }, [applicationsData, applicationsCollSnap]);

  const hideOrShowColumn = (value) => {
    const newColumnsToDisplay = columnsToDisplay.map(col => {
      if(col.value === value) {
        if(col.isSelected) {
          setTableFilters(filters => {
            return {...filters, [value]: null}
          })
          removeFilterIfColumnDeselected(value, authorizedUser.id, tableFiltersType)
        }
        return {...col, isSelected:!col.isSelected}
      } else {
        return col
      }
    });
    setColumnsToDisplay(newColumnsToDisplay);
    saveColumnsToLocalStorage(authorizedUser.id, newColumnsToDisplay, columnsType);
  }

  const resetColumns = () => {
    const newColumnsToDisplay = getDefaultSetOfColumns(role)
    setColumnsToDisplay(newColumnsToDisplay);
    saveColumnsToLocalStorage(authorizedUser.id, newColumnsToDisplay, columnsType);
  }

  const applyFilters = useCallback((selectedFilters) => {
    Object.entries(selectedFilters).forEach(entry => {
      const newFilters = {...tableFilters, [entry[0]]: entry[1]}
      setTableFilters(newFilters)
      saveFilterToLocalStorage(authorizedUser.id, newFilters, tableFiltersType);
      resetAppsPageNum();
    })
  },[authorizedUser.id, resetAppsPageNum, tableFilters, tableFiltersType])

  const handleTableChange = useCallback((pagination, filters, sorter, {action}) => {
    if(action === 'filter') {
      applyFilters(filters); // filters = либо массив, либо null
    }
  }, [applyFilters])

  if(tableError || countriesError ) {
    return <MyError error={tableError || countriesError } />
  }

  const downloadMoreApps = () => {
    addAppsPage();
  }

  return (
    <div className='applications'>

      {clientUID ? (
        <>
          {/* <Breadcrumb
            // items={}
          /> */}
          <Button style={{marginLeft:'20px'}} icon={<ArrowLeftOutlined /> } onClick={() => window.history.back()}>Назад</Button>
          <h1 className='applications__title'>
            Заявки клиента <span className='title__client-uid'>{clientUID}</span>
          </h1>
        </>
      ) : null}
      <div style={{display:"flex", flexDirection:'row-reverse'}}>
        <Paginator currentCount={tableData.length} totalCount={totalCount} />
        <ColumnSelector columnsToDisplay={columnsToDisplay} hideOrShowColumn={hideOrShowColumn} resetColumns={resetColumns}/>
      </div>
      <Table
        size="small"
        loading={tableLoading}
        dataSource={tableData}
        columns={columns}
        pagination={false}
        onChange={handleTableChange}
        rowClassName={handleRowClassName}
      />
      <div style={{display:'flex', justifyContent:'center', marginTop: '1%'}}>
        <Button
          loading={tableLoading}
          onClick={downloadMoreApps}
          disabled={totalCount === tableData.length}
        >
          Загрузить еще
        </Button>
      </div>
    </div>
  )
})

export default Applications;
