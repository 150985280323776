import * as renderers  from '../utils/columnsRenderers';

const allColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: "center",
    width: 70,
    render: 'idRender'
  },
  {
    title: 'Country',
    dataIndex: 'countryFullName',
    key: 'countryFullName',
    align: "center",
    ellipsis: true,
    // filters:[], // запишется после загрузки стран в функции getColumnsConfig.
    // filterSearch: countrySearch,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: "center",
    // filterDropdown: (props) => <DateFilter filterDropdownProps={props}/>,
  },
  {
    title: 'IIN',
    dataIndex: 'iin',
    key: 'iin',
    align: "center",
  },
  // {
  //   title: 'Payment date',
  //   dataIndex: 'paymentDate',
  //   key: 'paymentDate',
  //   align: "center",
  // },
  {
    title: 'Applicant',
    dataIndex: 'applicant',
    key: 'applicant',
    align: "center",
  },
  {
    title: 'Citizenship',
    dataIndex: 'citizenship',
    key: 'citizenship',
    align: "center",
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: "center",
    // ellipsis: true,
    // width: "200px",
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    align: "center",
  },
  {
    title: 'Applicants number',
    dataIndex: 'applicants',
    key: 'applicants',
    align: "center",
    width:85,
  },
   {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: "center",
    width: 90,
    render: 'statusRender',
    // filters: getStatusesFilters(),
  },
  {
    title:'Payment',
    dataIndex: 'payment',
    key: 'payment',
    align: 'center',
    width:90,
    render: 'PaymentIconRender',
    // filters: getPaymentFilters(),
  },
  {
    title:'Total price',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    align: 'center',
  },
  {
    title: 'Viser',
    dataIndex: 'assignedTo',
    key: 'assignedTo',
    align: "center",
    filterMode: 'tree',
    // filterDropdown: (props) => <AssignedToCustomizedFilter filterProps={props}/>,
    render: 'assignedToRender',
  },
  {
    title: '',
    dataIndex: 'isApproved',
    key: 'isApproved',
    align: "center",
    width: 130,
    // filterDropdown: (props) => <AssignedToCustomizedFilter filterProps={props}/>,
    render: 'approveRender',
  }
];

export default function getCompanyApplicationColumns(handleApprove)  {
  return allColumns.map(column => {
    if(column.render) {
      if(column.render === 'approveRender') {
        return {...column, render:(_text, record) => renderers[column.render](record, handleApprove)}
      }
      return {...column, render: renderers[column.render]};
    }
    return column;
  })
}
