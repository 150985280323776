import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ConfigProvider, Popover } from 'antd';
import '../../assets/components/popover-info.scss';

const PopoverInfo = ({text}) => {

  const textArray = text.split('/n');
  const content = (
    <ul>
      {textArray.map((text, index) => <li key={index}>{text}</li>)}
    </ul>
  )

  return (
    <div className='popover-info'>
      <ConfigProvider
        theme={{
          components: {
            Popover: {
              fontSize: '13px'
            }
          }
        }}
      >
        <Popover content={content} title='Подсказка' style={{fontSize:'12px'}}>
          <InfoCircleOutlined style={{fontSize:'18px', color:"red"}}/>
        </Popover>
      </ConfigProvider>
    </div>

  );
};

export default PopoverInfo;