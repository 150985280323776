import { DatePicker, Form, Input, Select } from 'antd';
import React from 'react';
const { TextArea } = Input;

// const formInitialValues = {
//   email: undefined,
//   // role: 'employee',
// }

// const roleSelectOptions = [
//   {
//     value: 'employee',
//     label: 'Сотрудник',
//   },
//   {
//     value: 'companyManager',
//     label: 'Менеджер компании',
//   }
// ]

const NewEmployeeForm = ({form, createEmployee}) => {

  const handleFormFinish = (values) => {
    createEmployee(values)
  }

  return (
    <Form
      form={form}
      onFinish={handleFormFinish}
      name='new-employee-form'
      preserve={false}
      // initialValues={formInitialValues}
    >
      <Form.Item
        name='email'
        label='Email'
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        required={true}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        label='Роль'
        style={{width:'50%'}}
        required={true}
        name='role'
      >
        <Select
          options={roleSelectOptions}
        />
      </Form.Item> */}
    </Form>
  );
};

export default NewEmployeeForm;