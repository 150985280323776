import { Table } from 'antd';
import React, { useMemo } from 'react';
import getCompanyColumns from '../models/companyColumns';
import '../../../assets/company-list/company-list.scss'
import { useCompanies } from '../../../pages/Companies/providers/CompaniesProvider';

const CompanyList = () => {
  const { companiesData, companiesLoading, companiesError } = useCompanies();

  const columns = useMemo(() => {
    return getCompanyColumns()
  }, [])

  return (
    <div className='company-list'>
      <h2 className='company-list__title'>Список компаний</h2>
      {companiesError ? 'Ошибка' : (
        <Table
          columns={columns}
          dataSource={companiesData}
          size='small'
          loading={companiesLoading}
          pagination={false}
          // rowClassName={getSectionsRowClass}
          // onChange={handleTableChange}
        />
      )}

    </div>
  );
};

export default CompanyList;