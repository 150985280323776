const GLOBAL_ROLES = {
  admin: "admin",
  operator: "operator",
  superOperator: 'superOperator',
  assistant:'assistant',
  careSupport: 'careSupport',
  companyManager: 'companyManager',
}
export const GLOBAL_ROLES_RU = {
  [GLOBAL_ROLES.admin]: 'Администратор',
  [GLOBAL_ROLES.operator]: 'Визовик',
  [GLOBAL_ROLES.superOperator]: 'Супервизовик',
  [GLOBAL_ROLES.assistant]: 'Ассистент',
  [GLOBAL_ROLES.careSupport]: 'Отдел заботы',
  [GLOBAL_ROLES.careSupport]: 'Отдел заботы',
  [GLOBAL_ROLES.companyManager]: 'Менеджер компании',

}

export default GLOBAL_ROLES;