import { useContext } from "react";
import { AllOperatorsContext } from "../../contexts/layoutContexts";
import Spinner from "../Spinner";

const AssigneeName = ({assigneeId}) => {
  const { allOperators, allOperatorsLoading, allOperatorsError } = useContext(AllOperatorsContext);
  if(allOperatorsLoading) {
    return <Spinner />
  }
  let operator = allOperators.find(operator => operator.id === assigneeId);
  if(assigneeId === '') {
    return 'Не назначен'
  }
  if(!operator) {
    return 'Не найден'
  }
  return operator.name;
};

export default AssigneeName;