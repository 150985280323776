import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import DateDivider from './DateDivider';
import { getMessageCreationDate } from '../helpers/getMessageCreationDate';
import { getMessageCreationTime } from '../helpers/getMessageCreationTime';
import { getClassNameForMessage } from '../helpers/getClassNameForMessage';
import { memoizedCreationDate } from '../helpers/memoizedCreationDate';
import { UserDataContext } from '../../../contexts/context';
import Message from './Message';
import EmptyFillingBox from './messages/EmptyFillingBox';
import UnreadMessagesArea from './messages/UnreadMessagesArea';
import { LayoutFuncsContext, ScrollModeContext } from '../../../contexts/layoutContexts';
import { useMessagesContext, useUnsentMessagesContext, useUpdateChatContext } from './ChatDataProvider';
import { getCountFromServer } from 'firebase/firestore';
import getMessagesQuery from '../firebase/getMessagesQuery';
import findUnreadMessages from '../helpers/findUnreadMessages';
import readUnreadMessages from '../firebase/readUnreadMessages';

const ChatMessages = ({assignedTo, sendMessageError, selectedChatSnapId, send }) => {
  //contexts
  const { authorizedUser, role } = useContext(UserDataContext);
  const { scrollMode } = useContext(ScrollModeContext);
  const { messagesPages, messagesData, messagesFirstLoading } = useMessagesContext();
  const { switchChatScrollMode } = useContext(LayoutFuncsContext);
  const { setMessagesPages } = useUpdateChatContext();
  const { unsentMessages } = useUnsentMessagesContext()
  // states
  const [ scrolledToTop , setScrolledToTop ] = useState(false)
  //refs
  const allMessages = useRef(null)
  const firstMessageOffset = useRef(null)
  // variables
  let result = [<EmptyFillingBox key={"invisible-container"}/>,];
  let unreadMessages = [];
  const isDateNew = memoizedCreationDate();

  /**
   * При доскролле до верха контейнера сообщений сохраняем отступ первого сообщения из массива и меняем стейт scrolledToTop : true
   */
  useLayoutEffect(() => {
    if(!messagesFirstLoading && !scrolledToTop) {
      const messagesContainer = allMessages.current;
      const handleScroll = () => {
        if(messagesContainer.scrollTop === 0) {
          const array = Array.from(allMessages.current.children).filter(child => child.classList.contains('message__container')) // чтобы в не учитывались даты и самый первый пустой контейнер.
          firstMessageOffset.current = array[0].offsetTop;
          setScrolledToTop(true)
        }
      }

      messagesContainer.addEventListener('scroll', handleScroll);
      return () => messagesContainer.removeEventListener('scroll', handleScroll);
    }
  }, [messagesFirstLoading, scrolledToTop])

  /**
   * Только при смене статуса скролла до верха решаем переключить страницу или нет.
   */
  useEffect(() => {
    if(scrolledToTop) {
      const switchPage = async () => {
        try {
          const aggregateSnapshot = await getCountFromServer(getMessagesQuery(selectedChatSnapId));
          if(aggregateSnapshot.data().count !== (messagesData.length - unsentMessages.length)) {
            setMessagesPages(prev => ++prev);
          }
        } catch (error) {
          setScrolledToTop(false);
          console.log(error)
        }
      }
      switchPage();
    }
  }, [scrolledToTop])

  /**
   * Только при смене набора сообщений после доскролла до верха контейнера сообщений - найти первое сообщение предыдущего набора
   * Проскроллить контейнер до этого сообщения с учетом его сохраненного сдвига от верха контейнера, который имелся до загрузки новых сообщений.
  */
  useLayoutEffect(() => {
    if(!messagesFirstLoading && scrolledToTop) {
      const array = Array.from(allMessages.current.children).filter(child => child.classList.contains('message__container')) // чтобы в не учитывались даты и самый первый пустой контейнер.
      const elementToScroll = array[array.length - ((messagesPages - 1) * 50)];
      allMessages.current.scrollTop = elementToScroll.offsetTop - firstMessageOffset.current
      setScrolledToTop(false);
    }
  }, [messagesData]) // не добавлять messagesPages, иначе эффект отработает раньше времени, а при обновлении resultMessagesDocSnaps функция не выполнится.

  useLayoutEffect(() => {
    // Включает/выключает режим скроллинга чата.
    if(!messagesFirstLoading) {
      const messagesContainer = allMessages.current;
      const handleScroll = () => {
        const scrolledToBottom = Math.abs(messagesContainer.scrollHeight - messagesContainer.clientHeight - Math.round(messagesContainer.scrollTop)) <= 1;
        switchChatScrollMode(!scrolledToBottom );
      }
      messagesContainer.addEventListener('scroll', handleScroll);
      return () => messagesContainer.removeEventListener('scroll', handleScroll);
    }
  }, [messagesFirstLoading, switchChatScrollMode])

  useLayoutEffect(() => {
    // Установка скролла в позицию самого низа списка сообщений.
    // Работает в случае scrollMode = false (чат пролистан к самому низу списка сообщений), чтобы при появлении нового сообщения чат скроллился книзу.
    if(!messagesFirstLoading && !scrollMode) {
      allMessages.current.scrollTop = allMessages.current.scrollHeight;
    }
  },[messagesFirstLoading, scrollMode, messagesData])

  useLayoutEffect(() => {
    // если я ответственный за клиента (назначен на чат и его незавершенные заявки) - прочитывать все непрочитанные сообщения пассивно,
    // но только в режиме scrollMode=false(чат пролистан к самому низу)
    // таким образом, если я не ответственный - значит я наблюдатель (приведение), то есть не прочитываю сообщений. Могу отправлять и наблюдать.\
    if(!messagesFirstLoading && assignedTo === authorizedUser.id) {
      try {
        const unreadMessages = findUnreadMessages(messagesData);
        if(!scrollMode && unreadMessages.length) {
          readUnreadMessages(unreadMessages, role, selectedChatSnapId)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },[assignedTo, authorizedUser.id, messagesData, messagesFirstLoading, role, scrollMode, selectedChatSnapId])

  messagesData.forEach(message => {
    const messageCreationDate = getMessageCreationDate(message.time.toMillis());
    const messageCreationTime = getMessageCreationTime(message.time.toDate()); // метод toDate возвращает js Date object с потерей точности до секунд.
    const classNameForMessage = getClassNameForMessage(message.sender, message.isFailed);

    if(isDateNew(messageCreationDate)) {
      result.push(
        <DateDivider
          key={messageCreationDate}
          date={messageCreationDate}
        />
      )
    }

    if(!message.isReadFromCRM) {
      // супервизовик как админ, если не назначен или как оператор если назначен.
      // админ и careSupport видит картину визовика.
      // визовик и назначенный супервизовик видит непрочитанные сообщения, если скроллит по чату.
      const meNotAssigned = authorizedUser.id !== assignedTo;
      const meAssigneeAndScrolling = authorizedUser.id === assignedTo && scrollMode;

      if (meNotAssigned || meAssigneeAndScrolling) {
        unreadMessages.push(
          <Message
            key={message.isTemporary ? `${message.id}-temp` : message.id}
            styleClass={classNameForMessage}
            message={message}
            time={messageCreationTime}
            send={send}
          />
        )
        return;
      }
    }

    result.push(
      <Message
        key={message.isTemporary ? `${message.id}-temp` : message.id}
        styleClass={classNameForMessage}
        message={message}
        time={messageCreationTime}
        send={send}
      />
    )
  })

  if (unreadMessages.length > 0) {
    result.push(
      <UnreadMessagesArea key="unread-notification" unreadMessages={unreadMessages}/>
    )
  }

  return (
    <ul ref={allMessages} className="chat__messages">
      {result}
    </ul>
  )
};

export default ChatMessages;