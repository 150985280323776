import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import getMessagesQuery from '../firebase/getMessagesQuery';

const useMessagesData = (UID, dialogueSnapId, messagesPages, unsentMessages, removeUnsentMessage) => {
  const [ messagesCollSnapshot, messagesLoading, messagesError ] = useCollection(getMessagesQuery(dialogueSnapId, messagesPages));
  const [ storedMessages, setStoredMessages ] = useState([])
  const [ tempMessages, setTempMessages ] = useState([]);
  const [ messagesFirstLoading, setMessagesFirstLoading ] = useState(true)

  useEffect(() => {
    setStoredMessages([]);
    setMessagesFirstLoading(true)
  }, [dialogueSnapId])

  useEffect(() => {
    if (messagesCollSnapshot && !messagesCollSnapshot.metadata.fromCache) {
      setStoredMessages(messagesCollSnapshot.docs.map(doc => ({...doc.data(), docId:doc.id})));
      setMessagesFirstLoading(false);
    }
  }, [messagesCollSnapshot])

  useLayoutEffect(() => {
    tempMessages.forEach(tempMsg => {
      const sameMsgButUnsentIndex = unsentMessages.findIndex(unsentMsg => unsentMsg.id === tempMsg.id);
      if(sameMsgButUnsentIndex !== -1) {
        setTempMessages(prev => prev.filter(temp => temp.id !== tempMsg.id));
        return;
      }
      const sameMsgButSuccessIndex = storedMessages.findIndex(storedMsg => storedMsg.id === tempMsg.id);
      if(sameMsgButSuccessIndex !== -1) {
        setTempMessages(prev => prev.filter(temp => temp.id !== tempMsg.id));
        return;
      }
    })
  },[storedMessages, unsentMessages])  // eslint-disable-line
  // включение в зависимости tempMessages не имеет смысла, к тому же приводит к бесконечному циклу.

  /**
   * При изменении только временных сообщений надо проверить нет ли неотправленных сообщений (повторная попытка отправки сообщения после ошибки отправки)
   * Найти и удалить неотправленное сообщение, если таковое имеется.
   * LayoutEffect - чтобы сделать подмену до рендера
   */
  useLayoutEffect(() => {
    if(unsentMessages.length) {
      tempMessages.forEach(tempMsg => {
        unsentMessages.forEach(unsentMsg => {
          if(unsentMsg.id === tempMsg.id) {
            removeUnsentMessage(UID, unsentMsg.id)
          }
        });
      })
    }
  }, [tempMessages])

  const addTempMessage = (tempMessage) => {
    setTempMessages(prev => [...prev, tempMessage])
  }

  const combineMessages = useCallback(() => {
    const combinedMessages = [...storedMessages, ...tempMessages, ...unsentMessages];
    combinedMessages.sort((a, b) => {
      const timeA = a.time.toMillis();
      const timeB = b.time.toMillis();
      if(timeA > timeB) return 1
      if(timeA < timeB) return -1
      return 0;
    })
    return combinedMessages;
  }, [storedMessages, tempMessages, unsentMessages])

  const combinedMessages = useMemo(() => messagesFirstLoading ? [] : combineMessages(), [combineMessages, messagesFirstLoading]);

  return [
    combinedMessages,
    messagesFirstLoading,
    messagesError,
    addTempMessage,
  ]
};

export default useMessagesData;