import { Button, Form, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import showNotification from '../../NotificationService/helpers/showNotification';
import NewEmployeeForm from '../../../components/NewEmployeeForm';
import { doc, getDocs, query, runTransaction, serverTimestamp, where } from 'firebase/firestore';
import getClientsRef from '../../../firebase/clients/getClientsRef';
import { useParams } from 'react-router-dom';
import { useCompany, useUpdateCompany } from '../../../pages/CompanyProfile/providers/CompanyProvider';
import Spinner from '../../../components/Spinner';
import { firestore } from '../../../firebase/firebase';

const modalStyles = {
  header: {
    textAlign:'center'
  }
}

const NewEmployee = () => {
  // const { reloadCompanies } = useUpdateCompanies();
  const { features } = useContext(UserDataContext);
  const [ form ] = Form.useForm();
  const { companyId } = useParams();
  const [ modalIsOpened, setModalIsOpened ] = useState(false);
  const [ answerIsLoading, setAnswerIsLoading ] = useState(false);
  const { notificationAPI } = useContext(ProgramContext);
  const { companyData, companyLoading, companyError } = useCompany();
  const { reloadEmployees } = useUpdateCompany();

  if(!features.canAddCompanyEmployee) {
    return null;
  }

  const openModalHandler = () => {
    setModalIsOpened(true)
  }

  const closeModalHandler = () => {
    setModalIsOpened(false);
  }

  const submitHandler = async () => {
    form.submit();
  }
  const submitForm = async (values) => {
    try {
      setAnswerIsLoading(true)
      const querySnap = await getDocs(query(getClientsRef(), where('email', '==', values.email)));
      if(!querySnap.docs.length) {
        throw new Error('Не найдено')
      }
      if(querySnap.docs.length > 1) {
        throw new Error('Найдено больше 1 пользователя')
      }
      const user = querySnap.docs[0].data();
      if(user.company?.id) {
        throw new Error('Пользователь уже закреплен за одной из компаний')
      }

      const companyInfoToUser = {
        id: companyId,
        addedAt: serverTimestamp(),
        companyName: {...companyData.name}
      }

      await runTransaction(firestore, async (transaction) => {
        const companyRef = doc(firestore, 'companies', companyId);
        const userRef = querySnap.docs[0].ref
        const count = 1;
        const companyDoc = await transaction.get(companyRef);
        transaction.update(companyRef, {employeesNumber: companyDoc.data().employeesNumber + count});
        transaction.update(userRef, {company: companyInfoToUser});
      })

      reloadEmployees();
      showNotification(notificationAPI, 'process', {processName: 'addCompanyEmployee', status:'success'})
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'addCompanyEmployee', status:'error'})
    } finally {
      setAnswerIsLoading(false);
      form.resetFields();
      setModalIsOpened(false);
    }
  }

  return (
    <div className='new-company'>
      {companyError && 'Ошибка'}
      {companyLoading && <Spinner />}
      {(companyData && !companyLoading && !companyError) && (
        <>
          <Button onClick={openModalHandler} type='primary' loading={companyLoading} disabled={companyLoading}>Добавить сотрудника</Button>
          <Modal
            open={modalIsOpened}
            centered
            width={500}
            onCancel={closeModalHandler}
            onOk={submitHandler}
            confirmLoading={answerIsLoading}
            destroyOnClose={true}
            okButtonProps={{
              disabled: answerIsLoading
            }}
            cancelButtonProps={{
              disabled: answerIsLoading
            }}
            title='Новый сотрудник'
            styles={modalStyles}
          >
            <NewEmployeeForm
              form={form}
              createEmployee={submitForm}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default NewEmployee;