import markdownIt from 'markdown-it';
import React from 'react';
import '../assets/components/markdown-renderer.scss';

const md = markdownIt();

const MarkdownRenderer = ({text}) => {
  const transformedText = md.render(text);
  console.log(transformedText);

  return (
    <div
      className='marked-text'
      dangerouslySetInnerHTML={{ __html: transformedText }}
    >
    </div>
  )
};

export default MarkdownRenderer;