import React from 'react';
import UploadAttachment from './UploadAttachment';

const SelectedAttachmentList = ({chatUploadAttachmentList}) => {

  const attachments = chatUploadAttachmentList.map(attachment => {
    return <UploadAttachment key={attachment.uid} attachment={attachment} />
  })

  return (
    <div className="chat-upload-attachment-list__container">
      {attachments}
    </div>
  );
};

export default SelectedAttachmentList;
