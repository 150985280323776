import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';

const AISecondaryRequestForm = ({form, answerIsLoading, FinishAiSecondaryForm, categoryLabel}) => {
  return (
    <Form
      name='aiForm'
      form={form}
      onFinish={FinishAiSecondaryForm}
      preserve={false}
      disabled={answerIsLoading}
    >
      <h2 style={{textAlign:'center'}}>{categoryLabel}</h2>
      <Form.Item
          name='requestText'
          rules={[
            {
              required: true,
              message: 'Поле не должно быть пустым!'
            },
          ]}
      >
        <TextArea
          rows={15}
          placeholder='Введите текст запроса'
        />
      </Form.Item>
    </Form>
  );
};

export default AISecondaryRequestForm;