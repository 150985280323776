import React, { createContext, useContext, useMemo } from 'react';
import useCompaniesData from '../hooks/useCompaniesData';

const CompaniesDataContext = createContext({});
const UpdateCompaniesContext = createContext({});

const CompaniesProvider = ({children}) => {
  const [ companiesData, companiesLoading, companiesError, reloadCompanies ] = useCompaniesData()

  const companiesDataContext = useMemo(() => {
    return {
      companiesData,
      companiesLoading,
      companiesError
    }
  }, [companiesData, companiesError, companiesLoading])

  const updateCompaniesContext = useMemo(() => {
    return {
      reloadCompanies
    }
  }, [reloadCompanies])

  return (
    <CompaniesDataContext.Provider value={companiesDataContext}>
      <UpdateCompaniesContext.Provider value={updateCompaniesContext}>
        {children}
      </UpdateCompaniesContext.Provider>
    </CompaniesDataContext.Provider>
  );
};

export default CompaniesProvider;

export const useCompanies = () => useContext(CompaniesDataContext)
export const useUpdateCompanies = () => useContext(UpdateCompaniesContext)