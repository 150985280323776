import React, { createContext, useContext, useMemo, useState } from 'react';
import useFinanceOperationsData from '../hooks/useFinanceOperationsData';
import dayjs from 'dayjs';
import useLastBalanceBeforeDate from '../hooks/useLastBalanceBeforeDate';
import increaseDateByDays from '../../../utils/increaseDateByDays';

const FinanceOperationsContext = createContext({})
const UpdateFinanceOperationsContext = createContext({})
const RangeFilterContext = createContext({})
const BalanceBeforeAndResultContext = createContext({});

const setInitialRange = () => {
  const now = dayjs();
  const today = now.set('hour', 0).set('minute', 0).set('second', 0)
  const firstDayOfMonthDate = new Date(now.year(),now.month(), 1)
  const firstDayOfMonth = dayjs(firstDayOfMonthDate);
  return [firstDayOfMonth, today]
}

const CompanyFinanceProvider = ({children}) => {
  const [ rangeFilter, setRangeFilter ] = useState(setInitialRange);
  const [ operationsData, operationsLoading, operationsError, operationsReload ] = useFinanceOperationsData(rangeFilter);
  const upperDate = useMemo(() => increaseDateByDays(rangeFilter[1], 1), [rangeFilter])
  const [ balanceBefore, balanceBeforeLoading, balanceBeforeError ] = useLastBalanceBeforeDate(rangeFilter[0]);
  const [ balanceResult, balanceResultLoading, balanceResultError, reloadBalanceResult ] = useLastBalanceBeforeDate(upperDate);

  const updateRangeFilter = (newData) => {
    setRangeFilter(newData);
  }

  const rangeFilterContext = useMemo(() => {
    return {
      rangeFilter
    }
  }, [rangeFilter])

  const financeOperationsContext = useMemo(() => {
    return {
      operationsData, operationsLoading, operationsError
    }
  }, [ operationsData, operationsError, operationsLoading])

  const balanceBeforeAndResultContext =  useMemo(() => {
    return {
      balanceBefore, balanceBeforeLoading, balanceBeforeError,
      balanceResult, balanceResultLoading, balanceResultError
    }
  }, [balanceBefore, balanceBeforeError, balanceBeforeLoading, balanceResult, balanceResultError, balanceResultLoading])

  const updateFinanceOperationsContext = useMemo(() => {
    return {
      operationsReload,
      updateRangeFilter,
      reloadBalanceResult
    }
  }, [operationsReload, reloadBalanceResult])

  return (
    <FinanceOperationsContext.Provider value={financeOperationsContext}>
      <UpdateFinanceOperationsContext.Provider value={updateFinanceOperationsContext}>
        <RangeFilterContext.Provider value={rangeFilterContext}>
          <BalanceBeforeAndResultContext.Provider value={balanceBeforeAndResultContext}>
            {children}
          </BalanceBeforeAndResultContext.Provider>
        </RangeFilterContext.Provider>
      </UpdateFinanceOperationsContext.Provider>
    </FinanceOperationsContext.Provider>
  )
};

export default CompanyFinanceProvider;

export const useFinanceOperations = () => useContext(FinanceOperationsContext);
export const useUpdateFinanceOperations = () => useContext(UpdateFinanceOperationsContext);
export const useRangeFilter = () => useContext(RangeFilterContext);
export const useBalanceBeforeAndResult = () => useContext(BalanceBeforeAndResultContext);
