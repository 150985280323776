export const renderEmployeeId = (text, record) => {
  return (
    <span
      style={{ color:"#0EA5E9", fontWeight:"800" }}
    >
      {text}
    </span>
  )
}

