import { Form, Input } from 'antd';
import React from 'react';

const AddManagerForm = ({addManager, form}) => {

  const handleFinishForm = (values) => {
    addManager(values);
  }

  return (
    <Form
      form={form}
      onFinish={handleFinishForm}
    >
      <Form.Item
        label='email'
        required={true}
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        name='email'
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default AddManagerForm;