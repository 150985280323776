import { Link } from "react-router-dom"

export const renderId = (text, record) => {
  return (
    <Link
      to={`/companies/${record.id}`}
      style={{ color:"#0EA5E9", fontWeight:"800" }}
    >
      {text}
    </Link>
  )
}

export const renderEmployeesNumber = (text) => {
  return text
}

export const renderBalance = (balanceData) => {
  const balance = Object.entries(balanceData).map((entry) => {
    return (
      <div style={{display:'flex'}} key={entry[0]}>
        <span style={{marginRight:'0.35rem'}}>{entry[0]}</span>
        <span>{entry[1]}</span>
      </div>
    )
  })
  return (
    <div>
      {balance}
    </div>
  )
}