import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { firestore } from '../../../firebase/firebase';
import { getDateFromTimestamp } from '../../../utils/getDateFromTimestamp';

const useCompanyApplicationsData = (companyId) => {
  const [ applicationsData, setApplicationsData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ applicationsError, setApplicationsError ] = useState(null);

  const fetchApplications = useCallback(async () => {
    if(companyId) {
      try {
        setApplicationsError(null)
        // setIsLoading(true);
        const querySnap = await getDocs(query(
          collection(firestore, 'applications'),
          where('isCreated', '==', true),
          where('companyId', '==', companyId),
          where('paymentType', '==', 'corporate'),
          orderBy("createdAt", "desc"),
        ));
        if(!querySnap.docs.length) {
          return;
        }

        setApplicationsData(querySnap.docs.map(doc => {
          const appData = doc.data();
          if (!appData.UID || !appData.documentID) {
            console.log('поступила заявка с ошибкой')
            console.log('UID: ', appData?.UID)
            console.log('documentID: ', appData?.documentID)
          }
          return {
            key: appData.documentID,
            accountIsDeleted: appData.accountDeleted,
            isApproved: appData.isApproved,
            application: appData,
            id: appData.documentID.slice(0,4),
            date: getDateFromTimestamp(appData.createdAt, 'withTime'),
            iin: appData.passports[0].IIN,
            applicant: appData.applicantName || `ID: ${appData.UID.slice(0,4)}`,
            citizenship: appData.citizenship.toUpperCase(),
            email: appData.applicantEmail,
            phone: appData.applicantPhone,
            applicants: appData.passports.length,
            payment: appData.paymentSuccessful,
            paymentDate: getDateFromTimestamp(appData.paymentInfo?.confirmDate, 'withTime'),
            totalPrice: `${appData.price_USD} KZT`,
            countryFullName: appData.country_name_ru,
            docId: doc.id,
          }
        }));
      } catch (error) {
        console.log(error);
        setApplicationsError(error.message)
      } finally {
        setIsLoading(false);
      }
    }
  },[companyId])

  useEffect(() => {
    fetchApplications()
  }, [fetchApplications])

  return [
    applicationsData,
    isLoading,
    applicationsError,
    fetchApplications
  ]
};

export default useCompanyApplicationsData;