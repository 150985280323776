import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "../../fonts/Roboto-Regular-normal.js";

export function generatePDF(data) {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const maxWidth = pageWidth * 0.8;
  doc.setFont('Roboto-Regular','normal', 400)

  const { date1, date2, company1, company2, company1bin, tableData, openingBalance, closingBalance } = data;

  // Заголовок
  doc.setFontSize(18);
  doc.text("Акт сверки", 105, 20, { align: "center" });

  // Подзаголовок
  doc.setFontSize(12);
  const subtitle = `взаимных расчетов за период с ${date1} по ${date2} между ${company1} и ${company2}.`;
  doc.text(subtitle, 10, 30, { maxWidth });

  // Тело документа
  const bodyText = `Мы, нижеподписавшиеся, ${company1}, с одной стороны, и ${company2} с другой стороны, составили настоящий акт сверки в том, что состояние взаимных расчетов по данным учета следующее:`;
  doc.text(bodyText, 10, 50, { maxWidth });

  // Заголовок таблицы
  const tableTitle = `По данным ${company1}, KZT`;

  // Позиции для таблицы
  const startX = 10;
  const startY = 80;

  // Таблица
  doc.autoTable({
    startY: startY,
    head:  [
      [{ content: tableTitle, colSpan: 5, styles: { halign: "center" } }],
      ["Дата", "Email", "Страна", "Тип операции", "Сумма"]
    ],
    body: [
      [{ content: `Сальдо на начало ${date1}`, colSpan: 4 }, openingBalance],
      ...tableData.map(row => [row.date, row.email, row.country, row.operationType, row.amount]),
      [{ content: `Сальдо на конец ${date2}`, colSpan: 4 }, closingBalance]
    ],
    styles: { halign: "center",font: "Roboto-Regular", overflow: "linebreak", border: '1px solid black'  },
    headStyles: {
      fillColor: [255, 255, 255], // Устанавливаем фон заголовков таблицы в белый
      textColor: 0,               // Черный цвет текста
      fontSize: 10,
      fontStyle: "bold",
      lineWidth: 0.1,             // Толщина границ
      // lineColor: [0, 0, 0],
    },
    theme: "grid",
  });

  // Информация о компании 1
  let currentY = doc.previousAutoTable.finalY + 10;
  doc.text(`От ${company1}`, 10, currentY, { maxWidth });

  currentY += 10;
  doc.text(`БИН: ${company1bin}`, 10, currentY, { maxWidth });

  currentY += 10;
  doc.text("_______________________", 10, currentY, { maxWidth });

  currentY += 10;
  doc.text("_________________________________________(_______________________)", 10, currentY, { maxWidth });

  doc.save("act-sverki.pdf");
}
