import { doc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../firebase/firebase';

const useCompanyManagerData = (managerId) => {
  const [ managerSnap, managerLoading, managerError, reload ] = useDocumentOnce(managerId ? doc(firestore, 'admins', managerId) : null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ managerData, setManagerData ] = useState({});

  useEffect(() => {
    try {
      if(managerId) {
        if(managerSnap) {
          setManagerData({...managerSnap.data(), docId: managerSnap.id});
        }
      }
    } finally {
      setIsLoading(false)
    }

  }, [managerId, managerSnap])

  return [
    managerData,
    isLoading,
    managerError,
  ]
};

export default useCompanyManagerData;