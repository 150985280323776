import React, { useContext, useState } from 'react';
import NewCompanyForm from '../../../components/NewCompanyForm';
import { Button, Form, Modal } from 'antd';
import createCompany from '../firebase/createCompany';
import createCompanyData from '../models/createCompanyData';
import '../../../assets/new-company/new-company.scss'
import showNotification from '../../NotificationService/helpers/showNotification';
import { ProgramContext } from '../../../contexts/context';
import { nanoid } from 'nanoid';
import { useUpdateCompanies } from '../../../pages/Companies/providers/CompaniesProvider';

const modalStyles = {
  header: {
    textAlign:'center'
  }
}

const NewCompany = () => {
  const { reloadCompanies } = useUpdateCompanies();
  const [ form ] = Form.useForm();
  const [ modalIsOpened, setModalIsOpened ] = useState(false);
  const [ answerIsLoading, setAnswerIsLoading ] = useState(false);
  const { notificationAPI } = useContext(ProgramContext);

  const openModalHandler = () => {
    setModalIsOpened(true)
  }

  const closeModalHandler = () => {
    setModalIsOpened(false);
  }

  const submitHandler = async () => {
    form.submit();
  }

  const submitForm = async (values) => {
    try {
      setAnswerIsLoading(true)
      const data = createCompanyData(values);
      await createCompany(data);
      reloadCompanies();
      showNotification(notificationAPI, 'process', {processName: 'newCompany', status:'success'})
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'newCompany', status:'error'})
    } finally {
      setAnswerIsLoading(false);
      form.resetFields();
      setModalIsOpened(false);
    }
  }

  return (
    <>
      <Button className='new-company companies__new-company' onClick={openModalHandler} type='primary'>Создать компанию</Button>
      <Modal
        open={modalIsOpened}
        centered
        width={500}
        onCancel={closeModalHandler}
        onOk={submitHandler}
        confirmLoading={answerIsLoading}
        destroyOnClose={true}
        okButtonProps={{
          disabled: answerIsLoading
        }}
        cancelButtonProps={{
          disabled: answerIsLoading
        }}
        title='Новая компания'
        styles={modalStyles}
      >
        <NewCompanyForm
          form={form}
          createCompany={submitForm}
        />
      </Modal>
    </>
  );
};

export default NewCompany;