import React, { useContext, useEffect } from 'react';
import { useCollection, useCollectionData, useCollectionDataOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import getAllClientApplications from '../firebase/getAllClientApplications';
import { getChatQueryForApplication } from '../../../firebase/chat/getChatQueryForApplication';
import getAllCountriesRef from '../../../firebase/countries/getAllCountriesRef';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import MyError from '../../../modules/Error/components/MyError';
import ApplicationWorkPage from './ApplicationWorkPage';
import { UserDataContext } from '../../../contexts/context';
import getAppIdToNavigate from '../helpers/getAppIdToNavigate';
import getAssistantsQuery from '../../../firebase/assistants/getAssistantsQuery';
import useClientData from '../../../modules/Chat/hooks/useClientData';

const ApplicationDownloadData = ({clientId}) => {
  // react-router
  const { appId } = useParams();
  const location = useLocation(); // сюда будет приходить  documentID по которому кликнули.
  const navigate = useNavigate();
  // контексты
  const { authorizedUser } = useContext(UserDataContext)
  // загрузка данных
  const [ allClientAppsData, allClientAppsCollSnapshotLoading, allClientAppsCollSnapshotError, allClientAppsCollSnapshot ] = useCollectionData(getAllClientApplications(clientId));
  const [ chatCollSnapshot, chatLoading, chatError ] = useCollection(getChatQueryForApplication(clientId));
  const [ countriesData, countriesLoading, countriesError, countriesDocSnapshot ] = useDocumentData(getAllCountriesRef());
  const [ assistantsData, assistantsLoading, assistantsEror ] = useCollectionDataOnce(getAssistantsQuery())
  const [ clientData, clientDataLoading, clientDataError ] = useClientData(chatCollSnapshot?.docs[0].get('UID'));

  useEffect(() => {
    // use cases:
    // 1) зашли чеpез таблицу - appId будет
    // 2) зашли через чат - appId отсутствует
    // 3) зашли по ссылке URI - может быть, а может не быть
    if(!allClientAppsCollSnapshotLoading && !chatLoading && !countriesLoading && !appId) {
      const applicationId = getAppIdToNavigate(allClientAppsData, authorizedUser)
      applicationId ? navigate(`${applicationId}`) : navigate(`/`) ; // если айди заявки не получено - значит у клиента нет заявок (в случае входа по URL)
    }
  },[allClientAppsCollSnapshotLoading, allClientAppsData, appId, chatLoading, countriesLoading, location?.state?.applicationId, navigate, authorizedUser])

  if(allClientAppsCollSnapshotLoading || chatLoading || countriesLoading || !appId || assistantsLoading) {
    return (
      <div style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:"center" }}>
        <Spin size="large"/>
      </div>
    )
  }

  if(allClientAppsCollSnapshotError || chatError || countriesError || assistantsEror) {
    return <MyError error={allClientAppsCollSnapshotError || chatError || countriesError || assistantsEror} />
  }

  return (
    <ApplicationWorkPage
      clientId={clientId}
      allClientAppsData={allClientAppsData}
      allClientAppsCollSnapshot={allClientAppsCollSnapshot}
      chatCollSnapshot={chatCollSnapshot}
      clientData={clientData && clientData.data()}
      countriesData={countriesData}
      countriesDocSnapshot={countriesDocSnapshot}
      assistantsData={assistantsData}
    />
  );
};

export default ApplicationDownloadData;
