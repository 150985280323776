import { Link } from "react-router-dom"
import TableStatusTag from "../../../components/TableStatusTag";
import PaymentIcon from "../../../components/PaymentIcon";
import AssignOperator from "../../AssignOperator/components/AssignOperator";
import { addDoc, collection, doc, runTransaction, serverTimestamp, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { Button, Tag } from "antd";
import AssigneeName from "../../../components/AssigneeName/AssigneeName";
import ApproveCompApplication from "../../../components/ApproveCompApplication";

const createStatusTag = (status) => {
  return <TableStatusTag status={status}/>;
}

export const idRender = (text, record, _) => {
  return (
    <span style={{ color:"#0EA5E9", fontWeight:"800" }}>{text}</span>
    // <Link
    //   to={`/application/${record.application.UID}/${record.application.documentID}`}
    //   style={{ color:"#0EA5E9", fontWeight:"800" }}
    // >

    // </Link>
  )
}

export const statusRender = (_, record) => {
  return createStatusTag(record.application.preparedInformation.preparationStatus)
}

export const PaymentIconRender = (text) => {
  return <PaymentIcon payment={text} />
}

export const assignedToRender = (_, record) => {
  const assignedTo = record.application.preparedInformation.assignedTo;
  return <AssigneeName  assigneeId={assignedTo}/>
}

export const approveRender = (record, handleApprove) => {
  const handleButtonClick = (_e) => {
    handleApprove(record)
  }

  return (
    <ApproveCompApplication isApproved={record.isApproved} handleButtonClick={handleButtonClick}/>
  )
}

