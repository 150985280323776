import { uploadBytesResumable } from "firebase/storage";
import { getFileRef } from "../../../firebase/firebase";
import { chatPaths } from "../../../firebase/chat/chatsPath";
import { getFileExtension } from "../../../utils/getFileExtension";
import { addPathToDownload } from "../helpers/addPathToDownload";

const uploadAttachments = async (attachments, chatUploadAttachmentList, applicantId) => {
  const promises = []
  try {
    chatUploadAttachmentList.forEach((attachment, index) => {
      const uploadResult = uploadBytesResumable(getFileRef(`${chatPaths.storageAttachmentsFolder}/${applicantId}/${Date.now()}.${getFileExtension(attachment.name)}`), attachment);
      promises.push(uploadResult);
      uploadResult.on('state_changed', (snapshot) => {
        // const percentTransferred = getPercent(snapshot.bytesTransferred, snapshot.totalBytes);
      }, error => {
        console.log(error)
        throw error;
        // TODO: обработать ошибки.
      }, async () => {
        attachments = addPathToDownload(index, uploadResult.snapshot.metadata.fullPath, attachments);
      })
    })
    await Promise.all(promises)
    return attachments;
  } catch (error) {
    throw error;
  }
};

export default uploadAttachments;