import { Form, Input } from 'antd';
import React from 'react';
const { TextArea } = Input;

const NewCompanyForm = ({form, createCompany}) => {

  const handleFormFinish = (values) => {
    createCompany(values)
  }

  return (
    <Form
      form={form}
      onFinish={handleFormFinish}
      name='new-company-form'
      preserve={false}
    >
      <Form.Item
        name='orgType'
        label='Тип организации'
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='mainName'
        label='Основное название'
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='additionalDescription'
        label='Дополнительное описание'

      >
        <Input />
      </Form.Item>
      <Form.Item
        name='phone'
        label='Телефон'
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='email'
        label='Имейл'
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='actualAdress'
        label='Фактический адрес'
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        required={true}
      >
        <TextArea
          autosize={{ minRows: 2, maxRows: 6 }}
        />
      </Form.Item>
      <Form.Item
        name='BIN'
        label='БИН'
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='legalAdress'
        label='Юридический адресс'
        rules={[{
          required:true,
          message:'Поле не должно быть пустым!'
        }]}
        required={true}
      >
        <TextArea
          autosize={{ minRows: 2, maxRows: 6 }}
        />
      </Form.Item>
    </Form>
  );
};

export default NewCompanyForm;