import React, { useContext, useState } from 'react';
import '../../../assets/company-manager/company-manager.scss';
import { Button, Card, Form, Modal } from 'antd';
import AddManagerForm from '../../../components/AddManagerForm';
import { deleteField, doc, getDocs, query, serverTimestamp, where, writeBatch } from 'firebase/firestore';
import getAdminsRef from '../../../firebase/admins/getAdminsRef';
import GLOBAL_ROLES from '../../../constants/constants';
import { useCompany, useUpdateCompany } from '../../../pages/CompanyProfile/providers/CompanyProvider';
import Spinner from '../../../components/Spinner';
import { firestore } from '../../../firebase/firebase';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import showNotification from '../../NotificationService/helpers/showNotification';
const CompanyManager = () => {
  const { companyData, companyLoading, companyError,  } = useCompany();
  const { companyReload } = useUpdateCompany()
  const [ modalOpen, setModalOpen ] = useState(false);
  const [form] = Form.useForm();
  const { notificationAPI } = useContext(ProgramContext);
  const {features} = useContext(UserDataContext);

  const handleModalOpen = () => {
    setModalOpen(true);
  }
  const handleModalClose = () => {
    setModalOpen(false);
  }
  const handleFinishForm = () => {
    form.submit()
  }
  const addManager = async (values) => {
    console.log(values)
    try {
      // если сейчас кто то уже закреплен - открепить его.
      const querySnap = await getDocs(query(getAdminsRef(), where('email', '==', values.email)));
      if(!querySnap.docs.length) {
        throw new Error('не найдено')
      }

      if(querySnap.docs.length > 1) {
        throw new Error('найдено больше 1 пользователя')
      }

      const admin = querySnap.docs[0].data();
      if(admin.role !== GLOBAL_ROLES.companyManager) {
        throw new Error('Можно прикрепить пользователя только с ролью "Менеджер компании')
      }
      if(admin.company?.id) {
        throw new Error('Пользователь уже закреплен за одной из компаний')
      }

      const companyInfoToManager = {
        id: companyData.id,
        addedAt: serverTimestamp(),
        companyName: {...companyData.name}
      }

      const batch = writeBatch(firestore);
      const companyRef = doc(firestore, 'companies', companyData.docId);
      if(companyData.manager?.id) {
        const oldManagerRef = doc(firestore, 'admins', companyData.manager.id);
        batch.update(oldManagerRef, {company: deleteField()})
      }
      const manager = querySnap.docs[0].data();
      batch.update(companyRef, {manager: {
        name: manager.name,
        email: manager.email,
        phone: manager.phoneNumber,
        id: manager.id,
      }})
      batch.update(querySnap.docs[0].ref, {company: companyInfoToManager})
      await batch.commit();
      companyReload();
      setModalOpen(false);
      form.resetFields();
      showNotification(notificationAPI, 'process', {processName: 'addCompanyManager', status:'success'})
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'addCompanyManager', status:'error'})
    }
  }

  return (
    <div className='company-manager'>
      {companyLoading && <Spinner />}
      {(!companyLoading && !companyError) && (
        <>
          <Card
            title='Менеджер компании'
          >
            <p><span style={{fontWeight: '700'}}>Имя:</span> {companyData.manager.name}</p>
            <p><span style={{fontWeight: '700'}}>Email:</span> {companyData.manager.email}</p>
            <p><span style={{fontWeight: '700'}}>Тел:</span> {companyData.manager.phone}</p>
          </Card>
          {features.canAddManager ? (
            <>
              <Button type='primary' variant='dashed' onClick={handleModalOpen} block>Прикрепить менеджера</Button>
              <Modal
                open={modalOpen}
                onCancel={handleModalClose}
                onOk={handleFinishForm}
                styles={{
                  content: {
                    paddingTop:'3rem'
                  }
                }}
                title='Прикрепите менеджера'
              >
                <AddManagerForm
                  form={form}
                  addManager={addManager}
                  />
              </Modal>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default CompanyManager;