const getReconciliationReportData = (companyData, rangeFilter, openingBalance, closingBalance, operationsData) => {
  const docData = {
    company1: 'Товарищество с Ограниченной Ответственностью "Сервис Буду"',
    company2: `${companyData.name.organizationType} ${companyData.name.main} ${companyData.name.additionalDescription}`,
    date1: rangeFilter[0].format('DD.MM.YYYY'),
    date2: rangeFilter[1].format('DD.MM.YYYY'),
    company1bin: companyData.BIN,
    openingBalance: openingBalance,
    closingBalance: closingBalance,
    tableData: operationsData.map(operation => {
      return {
        date: operation.date,
        operationType: operation.operationType === 'replenishment' ? 'Дебет' : 'Кредит',
        amount: operation.transactionAmount,
        email: operation.email,
        country: operation.country,
      }
    })
  }
  return docData;
};

export default getReconciliationReportData;