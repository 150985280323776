import React from 'react';
import NewCompany from '../../../modules/NewCompany/components/NewCompany';
import CompanyList from '../../../modules/CompanyList/components/CompanyList';
import CompaniesProvider from '../providers/CompaniesProvider';
import '../../../assets/pages/companies.scss';

const Companies = () => {
  return (
    <CompaniesProvider>
      <div className='companies'>
        <NewCompany />
        <CompanyList />
      </div>
    </CompaniesProvider>
  );
};

export default Companies;