import { getDocs, limit, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { getBalanceHistoryRef } from '../../../firebase/companies/companies';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useCompanyId from '../../../layout/hooks/useCompanyId';

const useLastBalanceBeforeDate = (date) => {
  const  companyId = useCompanyId();
  const [ balance, setBalance ] = useState(null);
  const [ balanceLoading, setBalanceLoading ] = useState(true);
  const [ balanceError, setBalanceError ] = useState(null);

  const timestamp = useMemo(() => Timestamp.fromMillis(date.valueOf()), [date]);

  const fetchBalanceBefore = useCallback(async () => {
    try {
      setBalanceLoading(true)
      setBalanceError(null);
      const collSnap = await getDocs(query(getBalanceHistoryRef(companyId), where('createdAt', '<', timestamp), orderBy('createdAt', 'desc'), limit(1)));
      if(collSnap.empty) {
        setBalance(null);
        return;
      }
      setBalance(collSnap.docs[0].data());
    } catch (error) {
      console.log(error);
      setBalanceError(error.message);
    } finally {
      setBalanceLoading(false);
    }
  }, [companyId, timestamp])

  useEffect( () => {
    fetchBalanceBefore()
  }, [fetchBalanceBefore])

  return [
    balance, balanceLoading, balanceError, fetchBalanceBefore,
  ]
};

export default useLastBalanceBeforeDate;