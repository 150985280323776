import localforage from 'localforage';

let queue = Promise.resolve();

const getStorageKey = (applicantId) => {
  return `${applicantId}-chat-temp`;
}

export const getUnsentMessages = async (applicantId) => {
  const res = await localforage.getItem(getStorageKey(applicantId));
  return res || [];
}

export const saveUnsentMessages = async (applicantId, updatedTempMessages) => {
  await localforage.setItem(getStorageKey(applicantId), updatedTempMessages);
}

export const addUnsentMsgByUID = async (applicantId, newTempMessage) => {
  await enqueue(async () => {
    const res = await getUnsentMessages(applicantId);
    const updatedTempMessages = [...res, {...newTempMessage, time: newTempMessage.time.toMillis()}];
    await saveUnsentMessages(applicantId, updatedTempMessages)
  })
}

export const removeUnsentMsgByUID = async (applicantId, idToDelete) => {
  await enqueue(async () => {
    const res = await getUnsentMessages(applicantId);
    const updatedTempMessages = res.filter(unsentMsg => unsentMsg.id !== idToDelete);
    await saveUnsentMessages(applicantId, updatedTempMessages)
  })
}

const enqueue = (actionFunc) => {
  return new Promise ((resolve, reject) => {
    queue = queue.then(actionFunc).then(resolve)
  })
}