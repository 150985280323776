import { Button, Form, Input } from 'antd';
import React from 'react';


const AIMainRequestForm = () => {
  return (
    <Form
        layout='vertical'
      >
        <div className='form-inputs' style={{display:'flex', gap: '10px'}}>
          <Form.Item
            colon={true}
            layout='vertical'
            label='Case type :'
          >
            <Input/>
          </Form.Item>
          <Form.Item
            colon={true}
            layout='vertical'
            label='Case number :'
          >
            <Input/>
          </Form.Item>
          <Form.Item
            colon={true}
            layout='vertical'
            label='Name :'
          >
            <Input/>
          </Form.Item>
        </div>
        <Form.Item>
          <Button htmlType='submit' >Отправить запрос</Button>
        </Form.Item>
      </Form>
  );
};

export default AIMainRequestForm;