import * as renderers from '../utils/columnRenderers';

const columns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    align: "center",
    width: 70,
    render: 'renderId'
  },
  {
    title: 'Название ТОО',
    dataIndex: 'name',
    key: 'name',
    align: "center",
    // width: 70,
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    align: "center",
    // width: 70,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: "center",
    // width: 70,
  },
  {
    title: 'Сотрудники',
    dataIndex: 'employeesNumber',
    key: 'employeesNumber',
    align: "center",
    // width: 70,
    render:'renderEmployeesNumber'
  },
  {
    title: 'Баланс',
    dataIndex: 'balance',
    key: 'balance',
    align: "center",
    // width: 70,
    render:'renderBalance'
  }
]

export default function getCompanyColumns() {
  return columns.map(column => {
    if(typeof column.render === 'string') {
      return {
        ...column,
        render: renderers[column.render]
      }
    }
    return {...column}
  })
}