import React from 'react';
import '../../../assets/pages/ai-vkleyka/ai-vkleyka.scss';
import AIMainRequestForm from '../../../components/AIMainRequestForm';
import AIRequestCategories from '../../../modules/AIRequestCategories/components/AIRequestCategories';

const AIVkleyka = () => {
  return (
    <div className='ai-vkleyka'>
      <h3 className='ai-vkleyka__title'>
        AI Vkleyka &#40;Immigration and visa Expert&#41;
      </h3>
      <AIMainRequestForm />
      <AIRequestCategories />
    </div>
  );
};

export default AIVkleyka;