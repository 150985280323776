import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserDataContext } from '../../contexts/context';

const useCompanyId = () => {
  const { authorizedUser } = useContext(UserDataContext);
  const { companyId } = useParams();

  if(!companyId) {
    if(authorizedUser?.company?.id) {
      return authorizedUser.company.id;
    } else {
      return null;
    }
  } else {
    return companyId;
  }
};

export default useCompanyId;